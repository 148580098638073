<template>
  <div>
    <div class="maintitle">会议列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-button type="success" size="mini" @click="checkNewMeeting">更新会议列表</el-button>
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column type="expand" fixed>
        <template slot-scope="props">
          <el-form label-position="left" inline class="weblist-table-expand">
            <el-form-item label="线下报名开始时间">
              <span>{{ props.row.osstime }}</span>
            </el-form-item>
            <el-form-item label="线下报名结束时间">
              <span>{{ props.row.osetime }}</span>
            </el-form-item>
            <el-form-item label="报名开始时间">
              <span>{{ props.row.sstime }}</span>
            </el-form-item>
            <el-form-item label="报名结束时间">
              <span>{{ props.row.setime }}</span>
            </el-form-item>
            <el-form-item label="会议开始时间">
              <span>{{ props.row.mstime }}</span>
            </el-form-item>
            <el-form-item label="会议结束时间">
              <span>{{ props.row.metime }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="会议名称"></el-table-column>
      <el-table-column prop="area" label="地区" width="100"></el-table-column>
      <el-table-column label='操作' width='100'>
        <template slot-scope="scope">
          <el-button @click="showSign(scope)" size="mini">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
    };
  },
  methods: {
    checkNewMeeting() {
      this.$axios({
        url: this.HOST + '/admin.meeting/getNewMeeting',
        method: 'post',
        data: {}
      }).then(res => {
        this.$message({
          type: 'success',
          message: res.data.info
        })
        if (res.data.num) {
          this.getlist();
        }
      });
    },
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.meeting/list",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    showSign(scope){
      this.$router.push('/meeting/sign/'+scope.row.mid);
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
  },
  created: function () {
    this.getlist();
  },
};
</script>
<style>

</style>