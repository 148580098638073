<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-07 17:33:08
-->
<template>
  <div v-loading="loading">
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-date-picker v-model="startDate" type="date" placeholder="选择开始日期" size='mini' style='width:150px'></el-date-picker>
      <el-date-picker v-model="endDate" type="date" placeholder="选择结束日期" size='mini' style='width:150px' class="margin-left"></el-date-picker>
      <el-select size='mini' v-model='area' class='margin-left' @change='list=[]'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button size="mini" @click="getlist" class="margin-left">查询</el-button>
      <el-button size="mini" @click="recharge">充值</el-button>
      <span class='margin-left'>当前余额：{{formatMoney(balance)}}元</span>
    </el-row>
    <el-row>
      <el-col :span='12'>
        <el-tabs v-model="type" @tab-click="handleClick">
          <el-tab-pane label="保证金" name="guarantee"></el-tab-pane>
          <el-tab-pane label="会员资金池余额" name="member"></el-tab-pane>
          <el-tab-pane label="广告资金池余额" name="advert"></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-table :data="list" border>
      <el-table-column label='类型'>
        <template slot-scope="scope">
          {{formatType(scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column label='状态'>
        <template slot-scope="scope">
          {{formatStatus(scope.row.status)}}
        </template>
      </el-table-column>
      <el-table-column label='金额'>
        <template slot-scope="scope">
          {{formatMoney(scope.row.amount)}}
        </template>
      </el-table-column>
      <el-table-column label='时间'>
        <template slot-scope="scope">
          {{formatDate(scope.row.rechargeTime)}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column prop="operator" label="运营人员" width='80'></el-table-column>
     
      <el-table-column prop="contact" label="会员联系人" width='100'></el-table-column>
      <el-table-column prop="phone" label="联系电话" width='120'></el-table-column> -->
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      type: "guarantee",
      loading: false,
      startDate: this.getDate('today'),
      endDate: this.getDate(),
      balance: 0,
      bonusBalance: 0,
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    handleClick(tab, event) {
      this.page = 1;
      this.getlist();
    },
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/balance",
        method: "post",
        data: {
          type: this.type,
          page: this.page,
          pagesize: this.pagesize,
          startDate: this.startDate,
          endDate: this.endDate,
          area: this.area,
        },
      }).then((res) => {
        this.balance = res.data.balance;
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    recharge() {
      if (this.type == "guarantee") {
        this.$message({
          type: "warning",
          message: "不支持充值保证金",
        });
        return;
      }
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/recharge",
        method: "post",
        data: {
          type: this.type,
          area: this.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    formatMoney(money) {
      return money? money / 100:0;
    },
    formatType(type){
      let arr = [
        {
          id:1,
          name:'充值',
        },
        {
          id:2,
          name:'退款',
        },
        {
          id:3,
          name:'购买',
        },
      ]
      for(let item in arr){
        if(arr[item].id == type){
          return arr[item].name;
        }
      }
    },
    formatStatus(status){
      return status==1?'成功':'失败';
    },
    formatDate(time){
      let date = new Date(parseInt(time)*1000);
      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    }
  },
  created: function () {
    this.area = this.arealist[0].id;
    this.getlist();
  },
};
</script>