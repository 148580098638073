<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-07 17:33:08
-->
<template>
  <div v-loading="loading">
    <div class="maintitle">{{ title }}</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter="10">
      <el-col :span="6">
        <el-input v-model="keyword" :placeholder="
            type == 'member_id' ? '请输入会员id' : '请输入搜索关键词'
          " size="mini">
          <template slot="prepend">
            <el-select v-model="type" style="width: 100px" @change="keyword = ''">
              <el-option value="member_id" label="会员id"></el-option>
              <el-option value="keyword" label="公司名称"></el-option>
            </el-select>
          </template>
        </el-input>
      </el-col>
      <el-select size="mini" v-model="status">
        <el-option :value="999" label="全部"></el-option>
        <el-option :value="0" label="未认证"></el-option>
        <el-option :value="1" label="进行中"></el-option>
        <el-option :value="2" label="已认证"></el-option>
      </el-select>
      <el-select size="mini" v-model="area" class="margin-left" @change="list = []">
        <el-option v-for="item in arealist" :key="item.id" :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button @click="srch" size="mini" class="margin-left">搜索</el-button>
    </el-row>
    <el-row class="text-left">
      <el-button type="success" size="mini" @click="syncAuth" class="margin-left">更新真实认证状态</el-button>
      <el-button type="success" size="mini" @click="outexcel" class="margin-left">导出店铺数据</el-button>
      <el-button type="success" size="mini" @click="outAuth" class="margin-left">导出认证状态</el-button>
      <el-button type="success" size="mini" @click="syncMember" class="margin-left">更新会员</el-button>
      <el-button type="success" size="mini" @click="syncContract" class="margin-left">更新会员业务</el-button>
      <el-button type="success" size="mini" @click="getMemberInfo" class="margin-left">获取会员联系信息</el-button>
      <el-button type="success" size="mini" @click="getSellerPercent" class="margin-left">在线商户</el-button>
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column prop="operator" label="运营人员" width="80"></el-table-column>
      <!-- <el-table-column :formatter="formatStatus" label="审核状态">
        <template slot-scope="scope">
          <span v-html="formatStatus(scope.row.status)"></span>
        </template>
      </el-table-column> -->
      <el-table-column prop="contact" label="会员联系人" width="100"></el-table-column>
      <el-table-column prop="phone" label="联系电话" width="120"></el-table-column>
      <el-table-column label="真实认证" width="80">
        <template slot-scope="scope">
          <span v-html="chkstatus(scope.row.realstatus)"></span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="id" label="运营情况"></el-table-column> -->
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button type="text" @click="showScore(scope)" v-if="scope.row.realstatus != 1">店铺分</el-button>
          <el-button type="text" @click="sync(scope)">刷新</el-button>
          <el-button type="text" @click="showOrder(scope)">业务</el-button>
          <el-button size="text" @click="getAuthEndTime(scope)">认证状态</el-button>
          <!-- <el-tooltip class="item margin-right" effect="dark" content="认证" placement="top-start" v-if='scope.row.realstatus!=2'>
            <i class="el-icon-medal cursor-pointer" @click="authentication(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="运营人员" placement="top-start" v-if='scope.row.realstatus!=1'>
            <i class="el-icon-user cursor-pointer" @click="setOperator(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="店铺运营分" placement="top-start" v-if='scope.row.realstatus!=1'>
            <i class="el-icon-s-shop cursor-pointer" @click="showScore(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="智能小程序状态" placement="top-start" v-if='scope.row.realstatus!=1'>
            <i class="el-icon-cpu cursor-pointer" @click="getMiniProgram(scope)"></i>
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="setOperatorDiv" :show-close="false" :close-on-click-modal="false">
      <el-select v-model="crntOperator" placeholder="请选择运营人员" clearable>
        <el-option v-for="(item, index) in operatorlist" :key="index" :label="item.name" :value="item.uuid"></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setOperatorDiv = false">取 消</el-button>
        <el-button type="primary" @click="subSet">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showScoreDiv" :show-close="false" :close-on-click-modal="false">
      <el-table :data="tableData" height="450" border style="width: 100%">
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="score" label="分值"> </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showScoreDiv = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showOrderDiv" :show-close="false" :close-on-click-modal="false">
      <el-table :data="orderList" border style="width: 100%">
        <el-table-column prop="type" label="业务类型"></el-table-column>
        <el-table-column prop="end_time" label="到期时间"> </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showOrderDiv = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <span>{{ this.info }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
      keyword: "",
      crntscope: "",
      operatorlist: [],
      setOperatorDiv: false,
      crntOperator: "",
      setOperatorAuth: 0,
      type: "keyword", //关键字类型
      first: true,
      status: 999, //认证状态
      // 2022-01-20
      showScoreDiv: false,
      showOrderDiv: false,
      orderList: [],
      tableData: [],
      dialogVisible: false,
      info: "",
      loading: false,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
    };
  },
  methods: {
    getlist() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/list",
        method: "post",
        data: {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
          status: this.status,
          area: this.area,
          type: this.type,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
          this.setOperatorAuth = res.data.setOperatorAuth;
          if (this.setOperatorAuth == 1 && this.first) {
            this.getOperatorList();
            this.first = false;
          }
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    formatStatus(status) {
      let info = "";
      switch (parseInt(status)) {
        case 1:
          info = "待审核";
          break;
        case 2:
          info = "通过审核";
          break;
        case 3:
          info = "审核驳回";
          break;
        // case 4:
        //   info = '<a href="">登录</a>';
        //   break;
        default:
          info = "未知";
      }
      return info;
    },
    chkstatus(status) {
      let info = "";
      switch (parseInt(status)) {
        case 0:
          info = "未认证";
          break;
        case 1:
          info = "进行中";
          break;
        case 2:
          info = "已认证";
          break;
        case 7:
          info = "打款失败";
          break;
        default:
          info = "进行中";
      }
      return info;
    },
    //查看店铺分
    showScore(scope) {
      // var member_id = 38134288;
      var member_id = scope.row.member_id;
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/showScore",
        method: "post",
        data: {
          member_id: [member_id],
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.crntOperator = res.data.operator;
          this.showScoreDiv = true;
          this.tableData = res.data.list;
        }
      });
    },
    //查看会员业务详情
    showOrder(scope) {
      this.$axios({
        url: this.HOST + "/admin.member/getOrderList",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.orderList = res.data.list;
          this.showOrderDiv = true;
        }
      });
    },
    authentication(scope) {
      this.loading = true;
      let url = this.HOST + "/admin.member/getAuthenticationUrl";
      this.$axios({
        url: url,
        method: "post",
        data: {
          member_id: scope.row.member_id,
          type:'member_id',
          area:scope.row.area
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    syncAuth() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/syncAuth",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
          this.getlist();
        }
      });
    },
    setOperator(scope) {
      this.loading = true;
      this.crntscope = scope;
      this.$axios({
        url: this.HOST + "/admin.member/getOperator",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.crntOperator = res.data.operator;
          this.setOperatorDiv = true;
        }
      });
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    subSet() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/setOperator",
        method: "post",
        data: {
          member_id: this.crntscope.row.member_id,
          uuid: this.crntOperator,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
          this.setOperatorDiv = false;
        }
      });
    },
    syncMember() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/syncMember",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    syncContract() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/syncContract",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    sync(scope) {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/syncMemberByMemberId",
        method: "post",
        data: {
          id: scope.row.member_id,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    getMemberInfo() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/getMemberInfo",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    getAuthEndTime(scope) {
      this.$axios({
        url: this.HOST + "/admin.member/getAuthEndTime",
        method: "post",
        data: {
          id: scope.row.member_id,
          area: scope.row.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          if (res.data.url) {
            this.$confirm(scope.row.company_name + res.data.msg, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              window.open(res.data.url, "_blank");
            });
          } else if (res.data.authstatus == 0) {
            this.$confirm(scope.row.company_name + res.data.msg, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.authentication(scope);
            });
          } else {
            this.$confirm(scope.row.company_name + res.data.msg, {
              confirmButtonText: "重新认证",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.authentication(scope);
            });
          }
        }
      });
    },
    getSellerPercent() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/getSellerPercent",
        method: "post",
        data: {},
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    getOperatorList() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.operator/getlist",
        method: "post",
        data: {},
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.operatorlist = res.data.list;
        }
      });
    },
    getMiniProgram(scope) {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/getMiniProgram",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.info = res.data.info;
          this.dialogVisible = true;
        }
      });
    },
    outexcel() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/outexcel",
        method: "post",
        data: {
          keyword: this.keyword,
          area: this.area,
        },
        responseType: "blob",
      }).then((res) => {
        this.loading = false;
        // console.log(res);return;
        let blob = new Blob([res.data]);
        let fileName = "会员信息.csv";
        if ("download" in document.createElement("a")) {
          // 不是IE浏览器
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        } else {
          // IE 10+
          window.navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    outAuth() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.member/outAuth",
        method: "post",
        data: {
          area: this.area,
        },
        responseType: "blob",
      }).then((res) => {
        this.loading = false;
        // console.log(res);return;
        let blob = new Blob([res.data]);
        let fileName = "认证详情.csv";
        if ("download" in document.createElement("a")) {
          // 不是IE浏览器
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        } else {
          // IE 10+
          window.navigator.msSaveBlob(blob, fileName);
        }
      });
    },
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  created: function () {
    this.area = this.arealist[0].id;
    // this.getlist();
  },
};
</script>