<template>
  <div>
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-select v-model='type'>
        <el-option v-for='item in typelist' :key='item.type' :label="item.name" :value='item.type'></el-option>
      </el-select>
    </el-row>
    <el-form ref="form" label-width="60px">
      <el-form-item label="详情" prop="detail" :style="quillHeightAll" class="text-left">
        <div class="edit_container">
          <quill-editor v-model="detail" ref="myQuillEditor" class="editer" :options="editorOption" @ready="onEditorReady($event)" :style="quillHeight"></quill-editor>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="mdfysetting">修改</el-button>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
// 富文本样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      type: "",
      detail: "",
      title: '文章管理',
      typelist:[],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            // ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            // [{ size: ["small", false, "large", "huge"] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            // [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["image"],
          ],
        },
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    quillHeight() {
      return "height:" + (document.documentElement.clientHeight - 400) + "px";
    },
    quillHeightAll() {
      return "height:" + (document.documentElement.clientHeight - 350) + "px";
    },
  },
  components: {
    quillEditor,
  },
  methods: {
    onEditorReady(editor) {
      // addQuillTitle();
    },
    getsetting() {
      this.$axios({
        url: this.HOST + "/admin.index/getArticle",
        method: "post",
        data: {
          type: this.type,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.detail = res.data.detail;
        }
      });
    },
    getType() {
      this.$axios({
        url: this.HOST + "/admin.setting/getType",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.typelist = res.data.list;
        }
      });
    },
    mdfysetting() {
      this.$axios({
          url: this.HOST + "/admin.setting/setArticle",
          method: "post",
          data: {
            detail: this.detail,
            type: this.type,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              type: "success",
              message: res.data.info,
            });
          }
        });
    },
  },
  created: function () {
    this.type = this.$route.params.type;
    this.getType();
  },
};
</script>