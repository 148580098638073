<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-06 15:44:21
-->
<template>
  <div>
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-row>
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane v-for='item in typelist' :key='item.value' :name='item.value' :label="item.title"></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row class="text-left" :gutter='10'>
      <el-select size='mini' v-model='area' @change="handleClick">
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button size='mini' class="margin-left" @click="downData">下载数据</el-button>
      <el-button size='mini' class="margin-left" @click="updateData" v-if='hasUpdate==1'>更新数据</el-button>
      <span class="margin-left" v-if='hasUpdate==1'>最后更新时间：{{lasttime}}</span>
    </el-row>
    <el-row class="text-left" :gutter='10' v-if='hasUpdate==1'>
      <el-col :span='6'>
        <el-input v-model='member_id' placeholder="请输入会员id" size='mini'></el-input>
      </el-col>
      <el-button size='mini' class="margin-left" @click="getData">查询</el-button>
    </el-row>
    <el-dialog :visible.sync="dataDiv" :title='company' :show-close="false" :close-on-click-modal="false">
      <div class="excellentDiv">
        <li v-for='(item,index) in datalist' :index='index' :key='index'>
          <span>{{item}}</span>
        </li>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dataDiv = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      type: "",
      typelist: [],
      member_id: "",
      lasttime:'',
      datalist:[],
      company:'',
      dataDiv:false,
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    hasUpdate(){
      for(let item in this.typelist){
        if(this.typelist[item].value==this.type){
          return this.typelist[item].update
        }
      }
      return 0;
    }
  },
  methods: {
    handleClick(){
      this.$axios({
        url:this.HOST+'/admin.jiayoubao/getDate',
        method:'post',
        data:{
          type:this.type,
          area:this.area,
          hasUpdate:this.hasUpdate
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.lasttime = res.data.time
        }
      })
    },
    downData(){
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/updateData",
        method: "post",
        data: {
          area: this.area,
          type:this.type,
          op:'down'
        },
      }).then((res) => {
        if (res.data.status == 1) {
          window.open(res.data.url)
        }
      });
    },
    updateData() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/updateData",
        method: "post",
        data: {
          area: this.area,
          type:this.type,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.lasttime = res.data.time;
        }
      });
    },
    getType() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getDataType",
        method: "post",
        data: {
          area:this.area
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.typelist = res.data.list;
          this.type = res.data.type;
          this.lasttime = res.data.time;
        }
      });
    },
    getData() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/data",
        method: "post",
        data: {
          member_id: this.member_id,
          aera:this.area,
          type:this.type
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.datalist = res.data.list
          this.company = res.data.company
          this.dataDiv = true;
        }
      });
    },
  },
  created: function () {
    this.area = this.arealist[0].id;
    this.getType();
  },
};
</script>
<style>
  .excellentDiv{
    width:100%;
    padding:20px;
    display:flex;
    flex-wrap: wrap;
  }
  .excellentDiv > li{
    margin-right: 20px;
    padding:10px;
    list-style: none;
  }
</style>