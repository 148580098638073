<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-07 17:33:08
-->
<template>
  <div v-loading="loading">
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter='10'>
      <el-col :span='6'>
        <el-input v-model='keyword' :placeholder="type=='id'?'请输入服务商id':'请输入服务商全称'" size='mini'>
          <template slot="prepend">
            <el-select v-model="type" style="width:120px;" @change="keyword=''">
              <el-option value='id' label="服务商id"></el-option>
              <el-option value='name' label="服务商名称"></el-option>
            </el-select>
          </template>
        </el-input>
      </el-col>
      <el-select size='mini' v-model='status' class='margin-left'>
        <el-option :value='0' label='全部'></el-option>
        <el-option :value='1' label='待审核'></el-option>
        <el-option :value='2' label='已通过'></el-option>
        <el-option :value='3' label='未通过'></el-option>
        <el-option :value='4' label='待提交审核'></el-option>
      </el-select>
      <el-select size='mini' v-model='area' class='margin-left' @change='list=[]'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button @click="srch" size='mini' class="margin-left">搜索</el-button>
      <el-button @click="registerDiv=true" size='mini' class="margin-left">注册服务商</el-button>
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="username" label="账号" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column prop="agent_key" label="账号id"></el-table-column>
      <el-table-column prop="unified_code" label="信用码" width='180px'></el-table-column>
      <el-table-column prop="contact" label="联系人" width='100'></el-table-column>
      <el-table-column prop="phone" label="联系电话" width='120'></el-table-column>
      <el-table-column label="审核状态" width='80'>
        <template slot-scope="scope">
          <span v-html='formatStatus(scope.row.status)'></span>
        </template>
      </el-table-column>
      <el-table-column label="认证状态" width='80'>
        <template slot-scope="scope">
          <span v-html='chkrealstatus(scope.row.realstatus)'></span>
        </template>
      </el-table-column>
      <el-table-column label='操作' width='250'>
        <template slot-scope="scope">
          <el-button type='text' @click="realChkAgent(scope)" v-if='scope.row.realstatus!=2 && scope.row.status==2'>认证</el-button>
          <el-button type='text' @click="mdfy(scope)" v-if='scope.row.status!=2'>修改</el-button>
          <el-button type='text' @click="check(scope)" v-if='scope.row.status==1'>刷新</el-button>
          <el-button type='text' @click="del(scope)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="registerDiv" :show-close="false" :close-on-click-modal="false" @opened='initDiv' @closed="resetDiv">
      <el-form class="padding-left">
        <el-form-item label='账号' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.username' placeholder="请输入服务商账号,3~32位" maxlength='32' show-word-limit></el-input>
        </el-form-item>
        <el-form-item label='密码' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.password' placeholder="请输入服务商密码，6~32位" maxlength='32' show-word-limit type='password' show-password></el-input>
        </el-form-item>
        <el-form-item label='公司名称' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.company_name' placeholder="请输入服务商公司名称" maxlength='64' show-word-limit></el-input>
        </el-form-item>
        <el-form-item label='服务商信用代码' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.unified_code' placeholder="请输入服务商统一社会信用代码" maxlength='18' show-word-limit></el-input>
        </el-form-item>
        <el-form-item label='联系人' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.contact' placeholder="请输入服务商联系人" maxlength='6' show-word-limit></el-input>
        </el-form-item>
        <el-form-item label='联系电话' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.phone' placeholder="请输入服务商联系电话" maxlength='11' show-word-limit></el-input>
        </el-form-item>
        <el-form-item label='所在地区' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-select size='mini' v-model='spinfo.city'>
            <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='服务商电话' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='spinfo.company_tel' placeholder="请输入服务商电话"></el-input>
        </el-form-item>
        <el-form-item label='服务商网址' class="text-left" :label-width="labelWidth" size="mini">
          <el-input v-model='spinfo.company_site' placeholder="请输入服务商网址"></el-input>
        </el-form-item>
        <el-form-item label='服务商简介' class="text-left" :label-width="labelWidth" size="mini">
          <el-input v-model='spinfo.profile' placeholder="请输入服务商简介" maxlength='50' show-word-limit></el-input>
        </el-form-item>
        <el-form-item label='logo图片' class="text-left" :label-width="labelWidth" size="mini">
          <el-upload multiple :action="HOST + '/admin.sp/uploadPic'" name="logo" :data="uploadData" :auto-upload="true" :on-success="showRes" :show-file-list="false" :before-upload='showloading' v-if='spinfo.logo==""'>
            <el-button type="primary" plain>
              <i class="el-icon-upload el-icon--rihgt"></i>点击上传logo图片，jpg格式，200*200像素
            </el-button>
          </el-upload>
          <div class="avatorDiv" v-if="spinfo.logo != ''">
            <img :src="HOST+spinfo.logo" alt="">
            <div class="closebtn" @click="delPic('logo')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label='营业执照' class="text-left" :label-width="labelWidth" size="mini">
          <el-upload multiple :action="HOST + '/admin.sp/uploadPic'" name="license" :data="uploadData" :auto-upload="true" :on-success="showRes" :show-file-list="false" :before-upload='showloading' v-if='spinfo.license==""'>
            <el-button type="primary" plain>
              <i class="el-icon-upload el-icon--rihgt"></i>点击上传营业执照，jpg/png格式
            </el-button>
          </el-upload>
          <div class="avatorDiv" v-if="spinfo.license != ''">
            <img :src="HOST+spinfo.license" alt="">
            <div class="closebtn" @click="delPic('license')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label='合同' class="text-left" :label-width="labelWidth" size="mini">
          <el-upload multiple :action="HOST + '/admin.sp/uploadPic'" name="contract" :data="uploadData" :auto-upload="true" :on-success="showRes" :show-file-list="false" :before-upload='showloading' v-if='spinfo.contract==""'>
            <el-button type="primary" plain>
              <i class="el-icon-upload el-icon--rihgt"></i>点击上传合同，pdf格式
            </el-button>
          </el-upload>
          <div class="avatorDiv" v-if="spinfo.contract != ''">
            <img :src="HOST+spinfo.contract" alt="">
            <div class="closebtn" @click="delPic('contract')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="registerDiv = false">取 消</el-button>
        <el-button type="primary" @click="subReg">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="removeReasonDiv" :show-close="false" :close-on-click-modal="false" @closed="reason=''">
      <el-form class="padding-left">
        <el-form-item label='移除原因' class="text-left" :label-width="labelWidth" size="mini" required>
          <el-input v-model='reason' placeholder="请输入移除服务商原因"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="removeReasonDiv = false">取 消</el-button>
        <el-button type="primary" @click="subdel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
      keyword: "",
      status: 0,
      crntscope: "",
      registerDiv: false,
      loading: false,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      spinfo: {
        username: "",
        password: "",
        company_name: "",
        company_type: "企业",
        unified_code: "",
        contact: "",
        phone: "",
        city: "weifang",
        company_tel: "",
        company_site: "",
        profile: "",
        agent_key: "",
        logo: "",
        license: "",
        contract: "",
      },
      tempinfo: {},
      type: "name",
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
      loading: false,
      labelWidth: "120px",
      reason:'',
      removeReasonDiv:false,
    };
  },
  methods: {
    showloading() {
      this.loading = true;
    },
    getlist() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.sp/list",
        method: "post",
        data: {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
          area: this.area,
          type: this.type,
          status: this.status,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
          this.setOperatorAuth = res.data.setOperatorAuth;
          if (this.setOperatorAuth == 1 && this.first) {
            this.getOperatorList();
            this.first = false;
          }
          this.$message({
            type:'success',
            message:res.data.info
          })
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    formatStatus(status) {
      let info = "";
      switch (parseInt(status)) {
        case 1:
          info = "待审核";
          break;
        case 2:
          info = "已审核";
          break;
        case 3:
          info = "审核失败";
          break;
        case 4:
          info = '待提交';
          break;
      }
      return info;
    },
    chkrealstatus(status) {
      let info = "";
      switch (parseInt(status)) {
        case 1:
          info = "未完成";
          break;
        case 2:
          info = "已完成";
          break;
      }
      return info;
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    showRes(res) {
      this.loading = false;
      console.log(res);
      if (res.status == 1) {
        switch (res.type) {
          case "logo":
            this.spinfo.logo = res.pic;
            break;
          case "license":
            this.spinfo.license = res.pic;
            break;
          case "contract":
            this.spinfo.contract = res.pic;
            break;
        }
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
      console.log(this.spinfo);
    },
    subReg() {
      this.$axios({
        url:this.HOST+'/admin.sp/registerAgent',
        method:'post',
        data:{
          info:this.spinfo
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.$message({
            type:'success',
            message:res.data.info
          })
          this.registerDiv = false;
        }
      })
    },
    delPic(str) {
      this.$axios({
        url: this.HOST + "/admin.sp/delpic",
        method: "post",
        data: {
          pic: this.spinfo[str],
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.spinfo[str] = "";
        }
      });
    },
    initDiv() {
      if (this.crntscope == "") {
        this.spinfo.city = this.area;
      } else {
        this.$axios({
          url:this.HOST+'/admin.sp/getSpInfo',
          method:'post',
          data:{
            id:this.crntscope.row.id
          }
        }).then(res=>{
          if(res.data.status == 1){
            for(let i in this.spinfo){
              this.spinfo[i]= res.data.info[i]
            }
          }
        })
      }
    },
    resetDiv() {
      this.crntscope = "";
      for (let i in this.spinfo) {
        this.spinfo[i] = this.tempinfo[i];
      }
    },
    realChkAgent(scope){
      this.$axios({
        url:this.HOST+'/admin.sp/realChkAgent',
        method:'post',
        data:{
          agent_key:scope.row.agent_key
        }
      }).then(res=>{
        if(res.data.status == 1){
          scope.row.realstatus = res.data.realstatus;
          if(res.data.url){
            window.open(res.data.url, '_blank');
          }
        }
      })
    },
    mdfy(scope){
      this.crntscope = scope;
      for(let i in this.spinfo){
        // if(i != 'password')
        this.spinfo[i] = scope.row[i];
      }
      this.registerDiv = true;
    },
    del(scope){
      this.crntscope = scope;
      this.removeReasonDiv = true;
    },
    subdel(){
      this.$confirm("确定删除服务商"+this.crntscope.row.company_name+"吗？本操作不可恢复！！！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/admin.sp/removeAgent",
          method: "post",
          data: {
            unified_code:this.crntscope.row.unified_code,
            reason:this.reason
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              type: "success",
              message: res.data.info,
            });
            this.list.splice(this.crntscope.$index,1);
            this.crntscope = '';
          }
        });
      });
    },
    check(scope){
      this.$axios({
          url: this.HOST + "/admin.sp/queryRegisterAgent",
          method: "post",
          data: {
            id:scope.row.id
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              type: "success",
              message: res.data.info,
            });
            scope.row.status = res.data.authstatus;
            if(res.data.reason){
              this.$alert('审核失败，失败原因为：' + res.data.reason, '', {
              confirmButtonText: '确定'
            });
            }
          }
        });
    }
  },
  computed: {},
  created: function () {
    this.area = this.arealist[0].id;
    for (let i in this.spinfo) {
      this.tempinfo[i] = this.spinfo[i];
    }
  },
};
</script>