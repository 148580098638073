<template>
  <div>
    <div class="maintitle">操作员列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-col :span='6'>
        <el-input v-model="keyword" placeholder="请输入搜索关键词" size="mini"></el-input>
      </el-col>
      <el-button type="success" size="mini" @click="page=1;getlist()" class="margin-left">搜索</el-button>
      <el-button type="success" size="mini" @click="addDiv=true" class="margin-left">添加操作员</el-button>
    </el-row>
    <el-table :data="list" row-key="menuid" border>
      <el-table-column prop="account" label="账号" v-if="accounttype!=2"></el-table-column>
      <el-table-column prop="tel" label="电话" v-if="accounttype!=1"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="groupname" label="用户组"></el-table-column>
      <el-table-column label="状态" :formatter="formatStatus"></el-table-column>
      <el-table-column prop="logintime" label="最后登录时间"></el-table-column>
      <el-table-column prop="lastip" label="最后登录ip"></el-table-column>
      <el-table-column label='地区'>
        <template slot-scope="scope">
          {{formatArea(scope.row.area)}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item margin-right" content="修改" placement="top-start">
            <i class="el-icon-edit cursor-pointer" @click="mdfy(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" content="重置密码" placement="top-start">
            <i class="el-icon-refresh-right cursor-pointer" @click="resetpswd(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" :content="scope.row.status==1?'停用':'启用'" placement="top-start">
            <i class="cursor-pointer" :class="scope.row.status==1?'el-icon-turn-off':'el-icon-open'" @click="setStatus(scope)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @opened="initinfo" @closed="resetFormInfo">
      <el-form :model="operatorinfo" :rules="rules" ref="operatorinfo">
        <el-form-item prop="uuid" class="hidden">
          <el-input v-model="operatorinfo.uuid" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户组" :label-width="formLabelWidth" class="text-left" prop="groupid">
          <el-select v-model="operatorinfo.groupid" placeholder="请选择">
            <el-option v-for="(item,index) in groups" :key="index" :label="item.title" :value="item.groupid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" :label-width="formLabelWidth" prop="account" v-if="accounttype!=2">
          <el-input v-model="operatorinfo.account" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth" prop="tel" v-if="accounttype!=1">
          <el-input v-model="operatorinfo.tel" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
          <el-input v-model="operatorinfo.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="地区" :label-width="formLabelWidth" class='text-left'>
          <el-select v-model="operatorinfo.area" placeholder="请选择" multiple>
            <el-option v-for="(item,index) in arealist" :key="index" :label="item.value" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="状态" class="text-left" prop="status">
          <el-radio-group v-model="operatorinfo.status">
            <el-radio-button label="1">正常</el-radio-button>
            <el-radio-button label="0">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="operatorinfo.uuid==''">
          新用户默认密码：{{dfltpswd}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //操作员列表
      groups: [], //用户组列表
      menus: {}, //菜单列表
      addDiv: false,
      operatorinfo: {
        uuid: "",
        account: "",
        tel: "",
        name: "",
        groupid: "",
        status: 1,
        area: [],
      },
      page:1,
      total:0,
      keyword:'',
      tempinfo: {},
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      crntscope: "",
      accounttype: 3,
      dfltpswd: "",
      rules: {
        // title: [
        //   { required: true, message: "请输入菜单名称", trigger: "blur" },
        //   { min: 2, message: "长度不能小于两个汉字", trigger: "blur" }
        // ],
        // model: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
        // controller: [
        //   { required: true, message: "请输入控制器名称", trigger: "blur" }
        // ],
        // action: [{ required: true, message: "请输入方法名称", trigger: "blur" }]
      },
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.operator/getlist",
        method: "post",
        data: {
          keyword:this.keyword,
          page:this.page,
          pagesize:this.pagesize
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.admins.data;
          this.total = res.data.admins.total;
          this.groups = res.data.groups ? res.data.groups : [];
          this.accounttype = res.data.accounttype;
          this.dfltpswd = res.data.dfltpswd;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    formatStatus(row, column) {
      return row.status == 1 ? "正常" : "停用";
    },
    formathidden(row, column) {
      return row.isshow == 1 ? "正常" : "隐藏";
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + "/admin.operator/setStatus",
        method: "post",
        data: {
          info: {
            uuid: scope.row.uuid,
            status: status,
          },
        },
      }).then((res) => {
        if (res.data.status == 1) {
          scope.row.status = status;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetFormInfo() {
      for (let item in this.operatorinfo) {
        this.operatorinfo[item] = this.tempinfo[item];
      }
      this.crntscope = "";
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.addDiv = true;
    },
    initinfo() {
      if (this.crntscope != "") {
        for (let item in this.operatorinfo) {
          this.operatorinfo[item] = this.crntscope.row[item];
        }
      }
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/admin.operator/add",
        method: "post",
        data: {
          info: this.operatorinfo,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    formatArea(area){
      let temp = [];
      for(let item in area){
        for(let i in this.arealist){
          if(this.arealist[i].id == area[item]){
            temp.push(this.arealist[i].value);
          }
        }
      }
      return temp.join(',');
    },
    resetpswd(scope) {
      let uuid = scope.row.uuid;
      this.$confirm(
        "确定要重置" + scope.row.name + "的密码吗？本操作不可恢复",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin.operator/resetpswd",
          method: "post",
          data: {
            muuid: uuid,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$alert(
              "用户密码已重置为 " +
                res.data.newpassword +
                " ，请记录后再关闭本对话框，本密码不可重现！",
              "友情提示",
              {
                confirmButtonText: "确定",
              }
            );
          }
        });
      });
    },
  },
  created: function () {
    for (let item in this.operatorinfo) {
      this.tempinfo[item] = this.operatorinfo[item];
    }
    this.getlist();
  },
};
</script>
<style>
.toplevel {
  width: 100%;
  font-weight: bold;
}
.toplevel > .el-checkbox__label {
  font-size: 16px;
}
</style>