<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-03-22 17:57:46
-->
<template>
  <div id='task'>
    <div class="maintitle">问题列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-col :span='4'>
        <el-input v-model='keyword' placeholder="请输入标题关键字" size="mini"></el-input>
      </el-col>
      <el-select class="margin-left" size="mini" v-model='status'>
        <el-option value='all' label="全部"></el-option>
        <el-option v-for='item in statuslist' :key='item.id' :value="item.id" :label="item.status"></el-option>
      </el-select>
      <el-select size='mini' v-model='area' class='margin-left' @change='list=[]'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button class="margin-left" type="success" size="mini" @click="page=1;getlist()">查询</el-button>
      <el-button class="margin-left" type="success" size="mini" @click="subDiv = true">提交新问答</el-button>
      <el-button type="success" size="mini" @click="importDiv = true">批量导入</el-button>
    </el-row>
    <el-table :data="list" row-key="bag_word_id" border default-expand-all>
      <el-table-column prop="headline" label="标题"></el-table-column>
      <el-table-column prop="company_name" label="公司"></el-table-column>
      <el-table-column prop="query" label="搜索关键词" width='120'></el-table-column>
      <el-table-column prop="category" label="分类" width='80'></el-table-column>
      <el-table-column prop="keywords" label="关键词" width='200'></el-table-column>
      <el-table-column :formatter="formatStatus" label="状态" width='100'></el-table-column>
      <el-table-column label='操作' width='150'>
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="syncAsk(scope)" size="mini" type='text' v-if='scope.row.status!=2 && scope.row.status != 1'>更新</el-button>
            <el-button @click="sub(scope)" size="mini" type='text' class="margin-small-left" v-if='scope.row.status==1'>审核</el-button>
            <el-button @click="mdyfAsk(scope)" size="mini" type='text' class="margin-small-left" v-if='scope.row.status!=2'>修改</el-button>
            <el-button @click="showReason(scope)" size="mini" type='text' class="margin-small-left" v-if='scope.row.status==4'>原因</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="subDiv" :show-close="false" :close-on-click-modal="false" @closed='initInfo'>
      <el-form :model="askinfo">
        <el-form-item label="所属地区" :label-width='formLabelWidth' required class="text-left">
          <el-select size='mini' v-model='askinfo.area' class='margin-left'>
            <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员id" :label-width='formLabelWidth' required>
          <el-input v-model='askinfo.member_id' type='number' @change="getCompany"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" :label-width='formLabelWidth'>
          <el-input v-model='company' readonly></el-input>
        </el-form-item>
        <el-form-item label="搜索关键字" :label-width='formLabelWidth' required>
          <el-input v-model='askinfo.query' placeholder="请输入搜索关键字"></el-input>
        </el-form-item>
        <el-form-item label="问答标题" :label-width='formLabelWidth' required>
          <el-input v-model='askinfo.headline' placeholder="请输入问答标题"></el-input>
        </el-form-item>
        <el-form-item label="行业" :label-width='formLabelWidth'>
          <el-input v-model='askinfo.category' show-word-limit placeholder="请输入所属行业"></el-input>
        </el-form-item>
        <el-form-item label="关键词" :label-width='formLabelWidth' required>
          <el-input v-model='askinfo.keywords' placeholder="请输入关键词"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="subDiv = false">取 消</el-button>
        <el-button type="primary" @click="subAsk">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="importDiv" :show-close="false" :close-on-click-modal="false">
      <el-upload class="upload-demo" drag :action="HOST + '/admin.task/importAsk'"  ref="upload" :multiple="false" name='file' :data="uploadData" :show-file-list="false" accept='.csv' :on-success='showResult' :auto-upload='false'>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          只能上传csv文件
        </div>
      </el-upload>
      <el-button class="margin-bottom" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importDiv = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      subDiv: false,
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      askinfo: {
        id: "",
        member_id: "",
        query: "",
        headline: "",
        category: "",
        keywords: "",
        area:''
      },
      keyword: "",
      status: "all",
      tempinfo: {},
      crntscope: "",
      importDiv: false,
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
      company: "",
      statuslist: [],
      area: "",
    };
  },
  computed: {},
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.task/getAskList",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize,
          keyword: this.keyword,
          status: this.status,
          area: this.area,
        },
      }).then((res) => { 
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    showReason(scope){
      this.$alert(scope.row.reason,'错误原因',{
        confirmButtonText: '关闭',
      })
    },
    subAsk() {
      if(this.company==''){
        this.$message({
          type:'error',
          message:'会员信息错误，请修改后重试'
        })
        return;
      }
      this.$confirm("确定要提交问答信息吗？信息提交成功后不可修改！！！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/admin.task/subask",
          method: "post",
          data: {
            info: this.askinfo,
            area:this.area
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.getlist();
            this.$message({
              type: "info",
              message: "操作成功",
            });
            this.subAsk = false;
            this.initInfo();
          }
        });
      });
    },
    syncAsk(scope) {
      this.$axios({
        url: this.HOST + "/admin.task/syncAsk",
        method: "post",
        data: {
          id: scope.row.id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "info",
            message: "操作成功",
          });
          scope.row.status = res.data.askstatus;
        }
      });
    },
    submitUpload() {
      this.uploadData.area = this.area;
      this.$refs.upload.submit();
    },
    initInfo() {
      for (let i in this.askinfo) {
        this.askinfo[i] = this.tempinfo[i];
      }
      this.company = '';
    },
    mdyfAsk(scope) {
      this.crntscope = scope;
      for (let i in this.askinfo) {
        this.askinfo[i] = scope.row[i];
      }
      this.subDiv = true;
    },
    showResult(res) {
      if (res.status == 1) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.getlist();
        this.importDiv = false;
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    sub(scope) {
      for (let i in this.askinfo) {
        this.askinfo[i] = scope.row[i];
      }
      this.$confirm("确定要提交问答信息吗？信息提交成功后不可修改！！！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/admin.task/subask",
          method: "post",
          data: {
            info: this.askinfo,
            area:this.area
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.getlist();
            this.$message({
              type: "info",
              message: "操作成功",
            });
            this.initInfo();
          }
        });
      });
    },
    getCompany() {
      if (this.askinfo.member_id == "") {
        this.company = "";
        return;
      }
      this.$axios({
        url: this.HOST + "/admin.task/getCompanyInfo",
        method: "post",
        data: {
          member_id: this.askinfo.member_id,
          area:this.askinfo.area
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.company = res.data.company_name ? res.data.company_name : "";
        }
      });
    },
    formatStatus(column) {
      for (let item in this.statuslist) {
        if (column.status == this.statuslist[item].id) {
          return this.statuslist[item].status;
        }
      }
    },
    getStatus() {
      this.$axios({
        url: this.HOST + "/admin.task/getStatusList",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.statuslist = res.data.list;
        }
      });
    },
  },
  created: function () {
    this.getStatus();
    this.askinfo.area = this.area = this.arealist[0].id;
    // this.getlist();
    for (let i in this.askinfo) {
      this.tempinfo[i] = this.askinfo[i];
    }
  },
};
</script>