/*
 * @Author: minihorse
 * @Date: 2020-12-09 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-04 16:56:54
 * 
 */
const Layout = () => import('../pages/layout')
// const Mobile = () => import('../pages/layout/mindex')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },    
    {
        path: '/login',
        component: () => import(/* webpackChunkName:'second' */ '../pages/login/index.vue')
    },
    {
        path: '/index',
        redirect:'/setting'
    },
    // {
    //     path: '/mindex',
    //     redirect:'/mbaobei/list'
    // },
    {
        path: '/menu',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '菜单列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/menu/list')
            },
        ]
    },
    {
        path: '/operator',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '操作员列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/list')
            },
            {
                path: 'group',
                meta: {
                    title: '会员组列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/group')
            },
        ]
    },
    {
        path: '/member',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '会员列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/member/list')
            },
            {
                path: 'auth',
                meta: {
                    title: '运营相关'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/member/auth')
            },
            {
                path: 'miniprogram',
                meta: {
                    title: '会员小程序'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/member/miniprogram')
            },
        ]
    },
    {
        path: '/media',
        component: Layout,
        children: [
            {
                path: 'index',
                meta: {
                    title: '新媒体任务上传'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/media/index')
            },
        ]
    },
    {
        path: '/sp',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '二级服务商管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/sp/list')
            },
        ]
    },
    {
        path: '/task',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '任务列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/list')
            },
            {
                path: 'answer',
                meta: {
                    title: '回答列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/answer')
            },
            {
                path: 'ask',
                meta: {
                    title: '提交问题'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/task/ask')
            },
        ]
    },
    {
        path: '/jiayoubao',
        component: Layout,
        children: [
            {
                path: 'balance',
                meta: {
                    title: '资金池余额'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiayoubao/balance')
            },
            {
                path: 'buy',
                meta: {
                    title: '加油包购买'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiayoubao/buy')
            },
            {
                path: 'check',
                meta: {
                    title: '资格查询'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiayoubao/check')
            },
            {
                path: 'orderlist',
                meta: {
                    title: '订单管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiayoubao/orderlist')
            },
            
            {
                path: 'data',
                meta: {
                    title: '数据查询'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiayoubao/data')
            },
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            { // 总管理员设置全局参数
                path: 'list',
                meta: {
                    title: '参数设置'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/list')
            },
            { // 总管理员设置全局参数
                path: 'article',
                meta: {
                    title: '文章管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/article')
            },
        ]
    },
    {
        path: '/meeting',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '会议列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/meeting/list')
            },
            {
                path: 'sign/:mid',
                meta: {
                    title: '报名企业'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/meeting/sign')
            },
            {
                path: 'sign',
                meta: {
                    title: '报名企业'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/meeting/sign')
            },
        ]
    },
    {
        path: '/record',
        component: Layout,
        children: [
            {
                path: 'login',
                meta: {
                    title: '操作员登录记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/record/login')
            },
        ]
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path:'*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]
export default staticRoutes