<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-02-26 11:14:43
-->
<template>
  <div>
    <div class="maintitle">智能小程序状态查询</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter='10'>
      <el-col :span='6'>
        <el-input v-model='member_id' placeholder="请输入会员id" size='mini'></el-input>
      </el-col>      
      <el-select size='mini' v-model='area' class='margin-left' placeholder="请选择所属地区">
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button size='mini' class="margin-left" @click="getMiniProgram">查询</el-button>
    </el-row>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <span>{{this.info}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      member_id: '',
      dialogVisible:false,
      info:'',
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
    };
  },
  methods: {
    getMiniProgram() {
      this.$axios({
        url: this.HOST + '/admin.member/getMiniProgram',
        method: 'post',
        data: {
          member_id: this.member_id,
          area:this.area
        },
      }).then(res => {
        if (res.data.status == 1) {
          this.info = res.data.info;
          this.dialogVisible = true;
        }
      })
    }
  },
  created: function () {
    this.area = this.arealist[0].id;
  },
};
</script>