<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2022-03-14 16:37:18
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-03-15 09:45:11
-->
<template>
  <div>
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-form :model="mediainfo" ref="mediainfo">
      <el-form-item label="任务类型" :label-width="formLabelWidth" class="text-left">
        <el-select v-model="mediainfo.type" placeholder="请选择">
          <el-option :value='1' label='内容支持'></el-option>
          <el-option :value='2' label='配合总部完成线上活动'></el-option>
          <el-option :value='3' label='内容传播配合'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth" class="text-left">
        <el-input v-model="mediainfo.remark" auto-complete="off" placeholder="请输入备注信息"></el-input>
      </el-form-item>
      <el-form-item label="附件" :label-width="formLabelWidth" class="text-left">
        <el-upload class="upload-demo" drag :action="HOST + '/admin.media/upattachemnt'" :multiple="false" :data="uploadData" name='attachment' :show-file-list="false" :on-success="upload" v-if="mediainfo.attachment == ''">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            只能上传jpg/png/pdf文件
          </div>
        </el-upload>
        <div class="avatorDiv" v-if="mediainfo.attachment != ''">
            <span>{{mediainfo.attachment}}</span>
            <div class="closebtn" @click="mediainfo.attachment = ''">
              <i class="el-icon-close"></i>
            </div>
          </div>
      </el-form-item>
    </el-form>
    <div><el-button @click="sub">提交</el-button></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: this.$route.meta.title,
      mediainfo: {
        type: 1,
        remark: '',
        attachment: ''
      },
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
    };
  },
  methods: {
    upload(response){
      if (response.status == 1) {
        this.mediainfo.attachment = response.attachemnt;
      } else {
        this.$message({
          type: "error",
          message: response.info,
        });
      }
    },
    sub(){
      this.$axios({
        url:this.HOST+'/admin.media/sub',
        method:'post',
        data:{
          info:this.mediainfo
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.$message({
            type:'success',
            message:res.data.info
          })
        }
      })
    }
  },
  created: function () {
  }
};
</script>
