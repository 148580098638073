/*
 * @Author: HaoJG
 * @Date: 2021-08-09 10:48:34
 * @Lasteditors: HaoJG
 * @LastEditTime: 2021-08-09 11:44:16
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import staticRoutes from './staticRoute'
import whiteRoutes from './whiteRoute'
// import { Message } from 'element-ui'
// import store from '../store'
// import Cookie from 'js-cookie'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: staticRoutes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 路由跳转前验证
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start()
  // 判断用户是否处于登录状态
  // debugger
  if (sessionStorage.getItem('adminislogin')) {
    // 如果当前处于登录状态，并且跳转地址为login，则自动跳回系统首页
    // 这种情况出现在手动修改地址栏地址时
    if (to.path === '/login') {
      next({ path: '/index', replace: true })
    } else if (to.path.indexOf('/error') >= 0) {
      // 防止因重定向到error页面造成beforeEach死循环
      next()
    }
    // else {
    //   initRoute(router).then(() => {
    //     let isPermission = false
    //     console.log("进入权限判断")
    //     permissionList.forEach((v) => {
    //       // 判断跳转的页面是否在权限列表中
    //       if(v.path == to.fullPath){
    //         isPermission = true
    //       }
    //     })
    //     // 没有权限时跳转到401页面
    //     if(!isPermission){
    //       next({path: "/error/401", replace: true})
    //     } else {
    //       next()
    //     }
    //   })
    // }
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  } else {
    // 如果是免登陆的页面则直接进入，否则跳转到登录页面
    if (whiteRoutes.indexOf(to.path) > -1) {
      next()
    } else {
      let needlogin = true;
      for (let i in whiteRoutes) {
        if (to.path.indexOf(whiteRoutes[i]) == 0) {
          needlogin = false;
          break;
        }
      }
      if (needlogin) {
        next({ path: "/login", replace: true })
        NProgress.done()
      } else {
        next();
      }

    }
  }
})

router.afterEach(() => {
  NProgress.done()// 结束Progress
})

export default router
