<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-07 17:33:08
-->
<template>
  <div v-loading="loading">
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter='10'>
      <el-col :span='6'>
        <el-input v-model='keyword' placeholder="请输入搜索关键字" size='mini'></el-input>
      </el-col>
      <el-select size='mini' v-model='area' class='margin-left' @change='list=[]'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button @click="srch" size='mini' class="margin-left">搜索</el-button>
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column label='操作' width='250'>
        <template slot-scope="scope">
          <el-button type='text' @click="check(scope)">资格查询</el-button>
          <!-- <el-button type='text' @click="show(scope)">查看列表</el-button> -->
          <el-button type='text' @click="upFile(scope)">上传合同</el-button>
          <el-button type='text' @click="syncOrder(scope)">查看订单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="upContractDiv" :show-close="false" :close-on-click-modal="false">
      <el-form class="padding-left">
        <el-form-item label='合同类型' class="text-left" label-width="120px" size="mini">
          <el-select v-model="contracttype">
            <el-option v-for='item in contractlist' :key='item.id' :value='item.id' :label='item.label'></el-option>
          </el-select>
          <el-button @click="syncContractList" size="mini" class="margin-left">更新合同类型</el-button>
        </el-form-item>
        <el-form-item label='开始日期' class="text-left" label-width="120px" size="mini">
          <el-date-picker v-model="begin_time" type="date" placeholder="选择开始日期" size='mini' value-format="yyyy-MM-dd" editable></el-date-picker>
        </el-form-item>
        <el-form-item label='结束日期' class="text-left" label-width="120px" size="mini">
          <el-date-picker v-model="end_time" type="date" placeholder="选择结束日期" size='mini' value-format="yyyy-MM-dd" editable></el-date-picker>
        </el-form-item>
        <el-form-item label='合同文件' label-width="120px" class="text-left">
          <el-upload class="upload-demo" drag :action="HOST + '/admin.jiayoubao/upContract'" ref="upload" :multiple="false" name='file' :data="uploadData" :show-file-list="false" accept='.pdf' :on-success='showResult' :auto-upload='false'>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>选择文件</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传pdf文件
            </div>
          </el-upload>
          <el-button class="margin-bottom" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="upContractDiv = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :title='skutitle' :visible.sync="skuDiv" :show-close="false" :close-on-click-modal="false">
      <el-table :data="skulist" row-key="mid" border>
        <el-table-column prop="coreQuery" label="核心词"></el-table-column>
        <el-table-column prop="url" label="链接地址"></el-table-column>
        <el-table-column prop="capacity" label="商品流量状态"></el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <a :href="scope.row.url" target="_blank" style='color:#00f'>查看商品</a>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="skuDiv = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="orderlistDiv" :show-close="false" :close-on-click-modal="false" width='80%'>
      <el-table :data="orderlist" border>
        <el-table-column prop="order_id" label="订单id"></el-table-column>
        <el-table-column label="商品">
          <template slot-scope="scope">
            {{formatGoods(scope.row.goods_id)}}
          </template>
        </el-table-column>
        <el-table-column prop="speed_factor_msg" label="履约速度"></el-table-column>
        <el-table-column prop="holiday_promotion_msg" label="节假日投放"></el-table-column>
        <el-table-column prop="order_status_step" label="当前状态"></el-table-column>
        <el-table-column prop="begin_time" label="开始时间"></el-table-column>
        <el-table-column label='操作' width='150'>
          <template slot-scope="scope">
            <el-button type='text' @click="showItem(scope)" v-if='scope.row.order_status!=0'>查看订单信息</el-button>
            <!-- <el-button type='text' @click="pay(scope)" v-if='scope.row.realstatus!=1'>支付</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="orderitemDiv" :show-close="false" :close-on-click-modal="false" append-to-body>
        <el-row :gutter="10" v-for='item in orderItemList' :key='item'>
          <el-col :span='18'>{{item}}</el-col>
          <el-col :span='6'>
            <el-button size="mini" @click="showProduct(item)">查看商品</el-button>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="orderitemDiv=false">关闭</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="orderlistDiv=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
      keyword: "",
      loading: false,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      upContractDiv: false,
      crntMember: "",
      skuDiv: false,
      skulist: [],
      skutitle: "",
      min_sku: window.min_sku,
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
      contracttype: "",
      contractlist: sessionStorage.getItem("contractlist")
        ? JSON.parse(sessionStorage.getItem("contractlist"))
        : [],
      begin_time: this.getDate(),
      end_time: this.getDate(),
      orderlist: [],
      orderlistDiv: false,
      orderitemDiv: false,
      orderItemList: [],
      contractlist: [],
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    getlist() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/memberlist",
        method: "post",
        data: {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
          // status: this.status,
          area: this.area,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    check(scope) {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/check",
        method: "post",
        data: {
          member_id: scope.row.member_id,
          area: this.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.skulist = res.data.list;
          this.skuDiv = true;
          if (res.data.list.length >= min_sku) {
            this.skutitle = scope.row.company_name + "（可以购买）";
          } else {
            this.skutitle = scope.row.company_name + "（不能购买）";
          }
        }
      });
    },
    formatGoods(goods_id) {
      for (let item in this.contractlist) {
        if (this.contractlist[item].buytype == goods_id)
          return this.contractlist[item].label;
      }
      return "未知";
    },
    showItem(scope) {
      this.orderItemList = scope.row.loc_list.split(",");
      this.orderitemDiv = true;
    },
    show(scope) {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/showSku",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.skulist = res.data.list;
          this.skutitle = scope.row.company_name;
          this.skuDiv = true;
        }
      });
    },
    submitUpload() {
      this.uploadData.member_id = this.crntMember;
      this.uploadData.contract_type = this.contracttype;
      this.uploadData.begin_time = this.begin_time;
      this.uploadData.end_time = this.end_time;
      this.$refs.upload.submit();
    },
    upFile(scope) {
      this.crntMember = scope.row.member_id;
      this.contracttype = "";
      this.begin_time = this.getDate();
      this.end_time = this.getDate();
      delete this.uploadData.member_id;
      delete this.uploadData.contract_type;
      delete this.uploadData.begin_time;
      delete this.uploadData.end_time;
      console.log(this.uploadData);
      this.upContractDiv = true;
    },
    showResult(res) {
      if (res.status == 1) {
        this.$message({
          type: "success",
          message: res.info,
        });
        this.upContractDiv = false;
      }else{
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    syncContractList() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getContractList",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.contractlist = res.data.list;
          sessionStorage.setItem("contractlist", JSON.stringify(res.data.list));
        }
      });
    },
    getGoodsTypeList() {
      if (!sessionStorage.getItem("contractlist")) {
        this.$axios({
          url: this.HOST + "/admin.jiayoubao/getContractList",
          method: "post",
          data: {},
        }).then((res) => {
          if (res.data.status == 1) {
            this.contractlist = res.data.list;
            sessionStorage.setItem(
              "contractlist",
              JSON.stringify(res.data.list)
            );
          }
        });
      } else {
        this.contractlist = JSON.parse(sessionStorage.getItem("contractlist"));
      }
    },
    syncOrder(scope) {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getOrderInfo",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          if (res.data.list.length == 0) {
            this.$message({
              type: "success",
              message: "查询成功，暂无订单",
            });
          } else {
            this.orderlist = res.data.list;
            this.orderlistDiv = true;
          }
        }
      });
    },
  },
  created: function () {
    this.area = this.arealist[0].id;
    this.getGoodsTypeList();
  },
};
</script>
