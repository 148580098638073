<template>
  <div id="logindiv" :class="terminal=='pc'?'pclogin':'mobilelogin'">
    <div id="login" v-if="terminal=='pc'">
      <h3 style="margin-bottom:20px">
        <span>{{ webname }}</span>
      </h3>
      <el-row class="margin-large-bottom">
        <el-input prefix-icon="el-icon-user" type="text" v-model="tel" placeholder="请输入账号或手机号码"/>
      </el-row>
      <el-row class="margin-large-bottom">
        <el-input
            prefix-icon="el-icon-key"
            type="password"
            v-model="password"
            placeholder="请输入密码"
            @keyup.enter.native="submitForm"
        />
      </el-row>
      <el-row class="margin-large-bottom text-center">
        <el-button type="primary" @click="submitForm">登录</el-button>
      </el-row>
    </div>
    <div class="text-center" style="position:absolute;bottom:0;width:100%;">
      <a :href="beianurl" class="margin-right" :style="beiancolor_text" v-if="beian!=''">{{beian}}</a><a :href="gonganbeianurl" :style="beiancolor_text" v-if="gonganbeian!=''"><img v-if="gonganico!=''" height="20px" :src="gonganico">{{gonganbeian}}</a>
    </div>
    <div v-if="terminal=='mobile'" id="mlogin">
      <div class="title">
        <span>{{ webname }}</span>
      </div>
      <div class="padding">
        <el-row class="margin-large-bottom">
          <el-input prefix-icon="el-icon-user" type="text" v-model="tel" placeholder="请输入账号或手机号码"/>
        </el-row>
        <el-row class="margin-large-bottom">
          <el-input
              prefix-icon="el-icon-key"
              type="password"
              v-model="password"
              placeholder="请输入密码"
          />
        </el-row>
        <el-row class="margin-large-bottom text-center">
          <el-button type="primary" @click="submitForm">登录</el-button>
        </el-row>

        <div class="text-center" style="position:absolute;bottom:0;width:100%;">
          <a :href="beianurl" class="margin-right" v-if="beian!=''">{{beian}}</a><a :href="gonganbeianurl" v-if="gonganbeian!=''"><img v-if="gonganico!=''" height="20px" :src="gonganico">{{gonganbeian}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tel: "",
      password: "",
      beian: '',
      beianurl: '',
      gonganbeian:'',
      gonganbeianurl:'',
      gonganico:'',
      beiancolor:'#fff'
    };
  },
  mounted() {
    // this.request();
  },
  computed: {
    terminal() {
      if (
          /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
              navigator.userAgent
          )
      ) {
        return "mobile";
      } else {
        return "pc";
      }
    },
    beiancolor_text(){
      return "color:"+this.beiancolor;
    }
  },
  methods: {
    getinfo() {
      this.$axios({
        url: this.HOST + '/admin.index/getinfo',
        method: 'get',
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.webname = res.data.webname;
          sessionStorage.setItem('webname',res.data.webname);
          this.beian = res.data.beian;
          this.beianurl = res.data.url;
          this.gonganbeian = res.data.gonganbeian;
          this.gonganbeianurl = res.data.gonganbeian_url;
          this.gonganico = res.data.gongan_ico;
          this.beiancolor = res.data.beiancolor;
        }
      });
    },
    submitForm() {
      if (this.tel == "") {
        this.$message({
          type: "error",
          message: "请输入手机号或账号",
        });
        return;
      }
      if (this.password == "") {
        this.$message({
          type: "error",
          message: "请输入密码",
        });
        return;
      }
      this.$axios({
        method: "post",
        url: this.HOST + "/admin.login/login",
        data: {
          account: this.tel,
          password: this.password,
        },
      }).then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          sessionStorage.setItem("adminuuid", res.data.userinfo.uuid);
          sessionStorage.setItem("adminname", res.data.userinfo.name);
          sessionStorage.setItem("admintoken", res.data.token);
          sessionStorage.setItem("adminislogin", true);
          sessionStorage.setItem(
              "adminmenulist",
              JSON.stringify(res.data.menulist)
          );
          sessionStorage.setItem('arealist',JSON.stringify(res.data.arealist))
          // if (this.terminal == "pc") {
            this.$router.push('/'+res.data.menulist[0]['children'][0]['controller']+'/'+res.data.menulist[0]['children'][0]['action']);
          // } else {
          //   this.$router.push('/m'+res.data.menulist[0]['children'][0]['controller']+'/'+res.data.menulist[0]['children'][0]['action']);
          // }
        }
      });
    },
  },
  created() {
    // this.getinfo();
  }
};
</script>

<style>
.pclogin {
  background-image: url(../../assets/images/admin/login/login_bg.jpg);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#login {
  width: 600px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 25%;
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.puzzle-box {
  box-sizing: border-box;
}

.puzzle-container {
  box-sizing: border-box;
  width: 100%;
  display: inline-block !important;
}

.title {
  padding-top: 50px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  #puzzle-box,
  .slider-container {
    width: 100% !important;
  }

  .el-dialog {
    width: 80% !important;
  }
}
</style>
