<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-02-09 14:50:07
-->
<template>
  <div id='task'>
    <div class="maintitle">任务列表</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter="10">
      <el-col :span='4'>
        <el-input v-model='keyword' placeholder="请输入搜索关键字" size="mini"></el-input>
      </el-col>
      <el-col :span='4'>
        <el-input v-model='company' placeholder="请输入公司id或公司名称关键字" size="mini"></el-input>
      </el-col>
      <el-select size='mini' v-model='area'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button @click="page=1;getlist()" class='margin-left' size="mini">查询</el-button>
      <el-button type="success" size="mini" @click="getTasksFromBaidu" class='margin-left'>获取新任务</el-button>
    </el-row>
    <el-table :data="list" row-key="bag_word_id" border default-expand-all>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-form label-position="left" inline class="demo-table-expand">
            <span v-for="(item,index) in scope.row.company" :key='index' style='line-height:30px;margin-right:10px;margin-left:20px'>
              {{index+1}}. {{item.company_name}}
            </span>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="bag_word_id" label="任务id" width="80"></el-table-column>
      <el-table-column prop="headline" label="标题"></el-table-column>
      <el-table-column prop="category" label="分类" width='200'></el-table-column>
      <el-table-column prop="dispTime" label="分发时间" width='160'></el-table-column>
      <el-table-column prop="commodityWords" label="商品词" width='120'></el-table-column>
      <el-table-column label='操作' width='160'>
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="showAnswer(scope)" size="mini" type='success'>回答</el-button>
            <el-button @click="showRefuse(scope)" size="mini" type='danger'>拒绝</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="refuseDiv" :show-close="false" :close-on-click-modal="false">
      <el-form>
        <el-form-item label='请输入拒绝原因'>
          <el-input v-model='refuseReason' maxlength="50" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refuseDiv = false">取 消</el-button>
        <el-button type="primary" @click="refuseTask">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="answerDiv" :show-close="false" :close-on-click-modal="false" @closed="initanswer">
      <el-form :model="answer">
        <el-form-item label='落地页地址' :label-width="formLabelWidth">
          <el-input v-model='answer.loc' class="input-with-select" placeholder="如果没有请留空，不能重复">
            <el-select class='answer-task-select' v-model="answer.protocol" slot="prepend">
              <el-option label="http://" value="http://"></el-option>
              <el-option label="https://" value="https://"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="权重" :label-width='formLabelWidth'>
          <el-input v-model='answer.priority' type='number' min='0.1' max='1.0' step='0.1'></el-input>
        </el-form-item>
        <el-form-item label="问答主图" :label-width='formLabelWidth' class="text-left">
          <el-upload class="upload-demo" drag :action="HOST + '/admin.task/uppic'" :multiple="false" name='mainpic' :data="uploadData" :show-file-list="false" :on-success="setMainPic" v-if="answer.mainpic==''">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg文件，宽高比例1:1，建议不小于300*300像素，必须且只能传一张
            </div>
          </el-upload>
          <div class="picDiv" v-if="answer.mainpic != ''">
            <img :src="answer.mainpic" alt="" />
            <div class="closebtn" @click="clearMainPic">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="回答摘要" :label-width='formLabelWidth'>
          <el-input v-model='answer.abstract' type='textarea' placeholder="请输入回答摘要内容，必填" maxlength="120" show-word-limit></el-input>
        </el-form-item>
        <el-divider></el-divider>
        回答详情
        <div v-for="(it,index) in answer.paragraph" :key="'paragraph'+index" class='paragraphDiv'>
          <el-form-item label="段落标题" :label-width='formLabelWidth'>
            <el-input v-model='answer.paragraph[index].subTitle' maxlength="24" show-word-limit placeholder="请输入段落标题，可不填"></el-input>
          </el-form-item>
          <el-form-item label="段落主图" class="text-left" :label-width='formLabelWidth'>
            <el-upload class="upload-demo" drag :action="HOST + '/admin.task/uppic'" :multiple="false" :name="'paragraphpic'+index" :data="uploadData" :show-file-list="false" :on-success="setParagraphPic" v-if="answer.paragraph[index].canonicalImage == ''">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                只能上传jpg文件，宽高比例1:1，建议不小于300*300像素，可不传
              </div>
            </el-upload>
            <div class="picDiv" v-if="answer.paragraph[index].canonicalImage != ''">
              <img :src="answer.paragraph[index].canonicalImage" alt="" />
              <div class="closebtn" @click="clearParagraphPic(index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="段落详情" :label-width='formLabelWidth'>
            <el-input v-model='answer.paragraph[index].content' type='textarea' placeholder="请输入段落内容，必填"></el-input>
          </el-form-item>
          <el-button @click="delParagraph(index)" type='danger'>删除该段落</el-button>
        </div>
        <div class="padding-large-bottom">
          <el-button @click="addParagraph" type='primary'>添加段落</el-button>
        </div>
        <el-form-item label="资料来源" :label-width='formLabelWidth'>
          <el-input v-model='answer.source' placeholder="请输入资料来源，可不填"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        商家信息
        <el-form-item label='商家' :label-width="formLabelWidth">
          <el-input v-model='answer.xzhId' class="input-with-select" @change='getCompanyInfo'>
            <el-select class='task-company-select' slot="prepend" v-model='answer.xzhId' @change='getCompanyInfo'>
              <el-option v-for="(item,index) in companylist" :key="index" :label="item.company_name" :value="item.member_id"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label='商家名称' :label-width="formLabelWidth">
          <el-input v-model='answer.provider.name' readonly placeholder="商家信息必选"></el-input>
        </el-form-item>
        联系人信息(可不填)
        <div v-for="(item,index) in answer.provider.contactPerson" :key="'contact'+index" class="paragraphDiv">
          <el-form-item label='联系人姓名' :label-width="formLabelWidth">
            <el-input v-model='answer.provider.contactPerson[index].name'></el-input>
          </el-form-item>
          <el-form-item label='联系人手机' :label-width="formLabelWidth">
            <el-input v-model='answer.provider.contactPerson[index].cellNumber' type='textarea' placeholder="最多可填写10个，以英文逗号(,)或回车分隔，非必填字段"></el-input>
          </el-form-item>
          <el-form-item label='联系人座机' :label-width="formLabelWidth">
            <el-input v-model='answer.provider.contactPerson[index].telephone' type='textarea' placeholder="最多可填写10个，以英文逗号(,)或回车分隔；区号与号码之间用“-”分隔，非必填字段"></el-input>
          </el-form-item>
          <el-form-item class="text-left" label='后续咨询' size="small" :label-width="formLabelWidth">
            <el-radio-group v-model="answer.provider.contactPerson[index].consultation">
              <el-radio-button label="是">接受</el-radio-button>
              <el-radio-button label="否">不接受</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-button @click="delProvider(index)" type='danger'>删除联系人</el-button>
        </div>
        <el-button @click="addProvider" type='primary'>添加联系人</el-button>
        <el-divider></el-divider>
        <el-form-item label='主营行业' :label-width="formLabelWidth">
          <el-input v-model='answer.provider.industry' type='textarea' placeholder="请输入主营行业，以英文逗号(,)或回车分隔，最多5个，非必填"></el-input>
        </el-form-item>
        <el-form-item label='主营产品' :label-width="formLabelWidth">
          <el-input v-model='answer.provider.businessScope' type='textarea' placeholder="请输入主营产品，以英文逗号(,)或回车分隔，最多3个，非必填"></el-input>
        </el-form-item>
        <el-form-item label="logo" class="text-left" :label-width='formLabelWidth'>
          <el-upload class="upload-demo" drag :action="HOST + '/admin.task/uppic'" :multiple="false" name="logoUrl" :data="uploadData" :show-file-list="false" :on-success="setLogo" v-if="answer.provider.logoUrl == ''">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg文件，宽高比例1:1，建议不小于170*170像素，不能大于4.5M，可不传
            </div>
          </el-upload>
          <div class="picDiv" v-if="answer.provider.logoUrl != ''">
            <img :src="answer.provider.logoUrl" alt="" />
            <div class="closebtn" @click="clearLogo">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="answerDiv = false">取 消</el-button>
        <el-button type="primary" @click="answerTask">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
      keyword:'',
      company:'',
      crntscope: "",
      first: true,
      refuseDiv: false,
      refuseReason: "",
      answerDiv: false,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      answer: {
        loc: "",
        protocol: "http://",
        priority: 1,
        headline: "",
        bag_word_id: 0,
        mainpic: "",
        abstract: "",
        paragraph: [
          {
            subTitle: "",
            canonicalImage: "",
            content: "",
          },
        ],
        source: "",
        xzhId: "",
        provider: {
          name: "",
          contactPerson: [
            {
              name: "",
              cellNumber: "",
              telephone: "",
              consultation: "是",
            },
          ],
          industry: "",
          businessScope: "",
          logoUrl: "",
        },
      },
      uploadData: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
      },
      tempanswer: {},
    };
  },
  computed: {
    companylist() {
      return this.crntscope == "" ? [] : this.crntscope.row.company;
    },
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.task/getlist",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize,
          area: this.area,
          keyword:this.keyword,
          company:this.company
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    showAnswer(scope) {
      this.crntscope = scope;
      this.answer.headline = scope.row.headline;
      this.answer.bag_word_id = scope.row.bag_word_id;
      this.answerDiv = true;
    },
    answerTask() {
      this.$axios({
        url: this.HOST + "/admin.task/answer",
        method: "post",
        data: {
          answer: this.answer,
          area:this.area
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "提交成功",
          });
          this.answer = {
            loc: "",
            protocol: "http://",
            priority: 1,
            headline: "",
            bag_word_id: 0,
            mainpic: "",
            abstract: "",
            paragraph: [
              {
                subTitle: "",
                canonicalImage: "",
                content: "",
              },
            ],
            source: "",
            xzhId: "",
            provider: {
              name: "",
              contactPerson: [
                {
                  name: "",
                  cellNumber: "",
                  telephone: "",
                  consultation: "是",
                },
              ],
              industry: "",
              businessScope: "",
              logoUrl: "",
            },
          };
          this.answerDiv = false;
        }
      });
    },
    showRefuse(scope) {
      this.crntscope = scope;
      this.refuseDiv = true;
    },
    refuseTask() {
      if (this.refuseReason == "") {
        this.$message({
          type: "error",
          message: "拒绝原因必填",
        });
        return;
      }
      this.$confirm(
        '确定要拒绝任务"' +
          this.crntscope.row.headline +
          '"吗？本操作不可恢复！！！',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin.task/refuseTask",
          method: "post",
          data: {
            id: this.crntscope.row.bag_word_id,
            reason: this.refuseReason,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            // this.getlist();
            this.list.splice(this.crntscope.$index, 1);
            this.$message({
              type: "info",
              message: res.data.info,
            });
            this.refuseDiv = false;
            this.crntscope = "";
          }
        });
      });
    },
    getTasksFromBaidu() {
      this.$axios({
        url: this.HOST + "/admin.task/getTasksFromBaidu",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
          this.getlist();
        }
      });
    },
    setMainPic(res) {
      if (res.status == 1) {
        this.answer.mainpic = res.url;
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    setParagraphPic(res) {
      if (res.status == 1) {
        let index = res.name.replace("paragraphpic", "");
        this.answer.paragraph[index].canonicalImage = res.url;
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    clearMainPic() {
      this.$axios({
        url: this.HOST + "/admin.task/delpic",
        method: "post",
        data: {
          pic: this.answer.mainpic,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.answer.mainpic = "";
        }
      });
    },
    setLogo(res) {
      if (res.status == 1) {
        this.answer.provider.logoUrl = res.url;
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    clearLogo() {
      this.$axios({
        url: this.HOST + "/admin.task/delpic",
        method: "post",
        data: {
          pic: this.answer.provider.logoUrl,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.answer.provider.logoUrl = "";
        }
      });
    },
    clearParagraphPic(index, op) {
      op = arguments[1] ? arguments[1] : false; //op为真是返回删除结果，为假时不返回结果
      if (this.answer.paragraph[index].canonicalImage == "") return true;
      this.$axios({
        url: this.HOST + "/admin.task/delPic",
        method: "post",
        data: {
          pic: this.answer.paragraph[index].canonicalImage,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.answer.paragraph[index].canonicalImage = "";
          if (op) {
            return true;
          }
        } else {
          return false;
        }
      });
    },
    addParagraph() {
      this.answer.paragraph.push({
        subTitle: "",
        canonicalImage: "",
        content: "",
      });
    },
    delParagraph(index) {
      if (this.clearParagraphPic(index, true)) {
        this.answer.paragraph.splice(index, 1);
        if (this.answer.paragraph.length == 0) {
          this.addParagraph();
        }
      } else {
        this.$message({
          type: "error",
          message: "操作失败",
        });
      }
    },
    addProvider() {
      this.answer.provider.contactPerson.push({
        name: "",
        cellNumber: "",
        telephone: "",
        consultation: "是",
      });
    },
    delProvider(index) {
      if (this.clearParagraphPic(index, true)) {
        this.answer.provider.contactPerson.splice(index, 1);
        if (this.answer.provider.contactPerson.length == 0) {
          this.addProvider();
        }
      } else {
        this.$message({
          type: "error",
          message: "操作失败",
        });
      }
    },
    getCompanyInfo() {
      if (!parseInt(this.answer.xzhId)) return;
      this.$axios({
        url: this.HOST + "/admin.task/getCompanyInfo",
        method: "post",
        data: {
          member_id: this.answer.xzhId,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.answer.provider.name = res.data.company_name;
        }
      });
    },
    initanswer() { console.log(1);
      for (let item in this.answer) {
        this.answer[item] = this.tempanswer[item];
      }
    },
  },
  created: function () {
    this.area = this.arealist[0].id;
    for (let item in this.answer) {
      this.tempanswer[item] = this.answer[item];
    }
    this.getlist();
  },
};
</script>
<style>
.recomm-memb-div {
  width: 100%;
}
.recomm-memb-div:nth-child(2n + 1) > li {
  background: #eee;
}
.recomm-memb-div > li {
  float: left;
  line-height: 40px;
  list-style: none;
  padding: 0 10px;
  box-sizing: border-box;
}
.recomm-memb-div > li:nth-child(1) {
  width: 80%;
}
.recomm-memb-div > li:nth-child(2) {
  width: 20%;
  text-align: right;
}
.answer-task-select {
  width: 100px;
}
.task-company-select {
  width: 300px;
}
.picDiv {
  position: relative;
  width: 100%;
  /* height: 100px; */
}
.picDiv > .closebtn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 15px;
  width: 15px;
  padding: 0;
  display: inline-block;
  line-height: 1;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}
.picDiv > img {
  width: 100%;
}
.paragraphDiv {
  margin-bottom: 20px;
  border-bottom: 1px dashed gray;
  padding: 20px 0;
}
.paragraphDiv:last-child {
  border: none;
}
</style>