<template>
  <div>
    <div class="maintitle">会议{{type=='signup'?'报名':'签到'}}列表</div>
    <el-divider></el-divider>
    <el-row :gutter="10" class="text-left" v-if='mid!=""'>
      <el-col :span="6">
        <el-input v-model="keyword" placeholder="请输入搜索关键字" size="mini"></el-input>
      </el-col>
      <el-select v-model="type" size="mini">
        <el-option value="signup" label='报名'></el-option>
        <el-option value="checkin" label='签到'></el-option>
      </el-select>
      <el-button type="primary" size="mini" @click="serachMember" class="margin-left">查看{{type=='signup'?'报名':'签到'}}列表</el-button>
      <el-button type="success" size="mini" @click="addDiv=true">给客户报名</el-button>
      <el-button type="success" size="mini" @click="refreshSign">更新{{type=='signup'?'报名':'签到'}}列表</el-button>
      <el-button type="success" size="mini" @click="outExcel">导出{{type=='signup'?'报名':'签到'}}列表</el-button>
    </el-row>
    <el-table :data="list" border>
      <el-table-column prop="company" label="企业名称"></el-table-column>
      <el-table-column prop="name" label="联系人姓名" width="200"></el-table-column>
      <el-table-column prop="phone" label='联系电话' width='200'></el-table-column>
      <el-table-column prop="create_time" label='报名时间' width='200'></el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @closed="resetFormInfo('userinfo')" width='80%'>
      <div class="itemgroup" v-for="(item , index) in signuplist.items" :key='index'>
        <el-row :gutter="10">
          <el-col :span='2' class="text-right item-title">公司名称</el-col>
          <el-col :span='8'>
            <el-input v-model="signuplist.items[index].company" placeholder="请输入公司名称" size='mini'></el-input>
          </el-col>
          <el-col :span='2' class="text-right item-title">联系人</el-col>
          <el-col :span='4' class="text-left">
            <el-input v-model="signuplist.items[index].name" placeholder="请输入联系人姓名" size='mini'></el-input>
          </el-col>
          <el-col :span='2' class="text-right item-title">联系电话</el-col>
          <el-col :span='4' class="text-left">
            <el-input v-model="signuplist.items[index].phone" placeholder="请输入联系电话" size='mini'></el-input>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type='primary' @click="additem">添加公司</el-button>
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //报名企业列表
      addDiv: false,
      page: 1,
      total: 0,
      signuplist: {
        items: [
          {
            name: '',
            company: '',
            phone: '',
          }
        ]
      },
      keyword: "",
      mid: '',
      type: 'signup'
    };
  },
  methods: {
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.meeting/getSignList",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize,
          keyword: this.keyword,
          mid: this.mid,
          type: this.type
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    additem() {
      this.signuplist.items.push({
        name: '',
        company: '',
        phone: '',
      })
    },
    deleteItem(index) {
      this.templateinfo.items.splice(index, 1);
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    resetFormInfo() {
      this.signuplist = {
        items: [
          {
            name: '',
            company: '',
            phone: '',
          }
        ]
      }
    },
    submdfy() {
      this.type='signup';
      this.$axios({
        url: this.HOST + "/admin.meeting/subSignup",
        method: "post",
        data: {
          mid: this.mid,
          info: this.signuplist,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.addDiv = false;
          this.$message({
            type: "info",
            message: "操作成功",
          });
          if (res.data.fail) {
            this.$alert('电话号码为' + res.data.fail.join(',') + '的企业报名失败', '', {
              confirmButtonText: '确定'
            });
          }
          this.refreshSign();
        }
      });
    },
    refreshSign() {
      this.$axios({
        url: this.HOST + '/admin.meeting/refreshSign',
        method: 'post',
        data: {
          mid: this.mid,
          type: this.type
        }
      }).then(res => {
        this.$message({
          type: 'success',
          message: res.data.info
        })
        this.getlist();
      })
    },
    serachMember() {
      this.page = 1;
      this.getlist();
    },
    outExcel(){
      this.$axios({
        url: this.HOST + "/admin.meeting/outSignList",
        method: "post",
        data: {
          mid:this.mid,
          type:this.type
        },
        responseType: "blob"
      }).then(res => {
        let blob = new Blob([res.data]);
        let fileName = this.type=='signup'?'报名列表.csv':"签到列表.csv";
        if ("download" in document.createElement("a")) {
          // 不是IE浏览器
          let url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(url); // 释放掉blob对象
        } else {
          // IE 10+
          window.navigator.msSaveBlob(blob, fileName);
        }
      });
    },
  },
  created: function () {
    if(this.$route.params.mid){
      this.mid = this.$route.params.mid;
    }else{
      this.$axios({
        url:this.HOST+'/admin.meeting/getMid',
        method:'post',
        data:{}
      }).then(res=>{
        if(res.data.mid){
          this.mid=res.data.mid
        }else{
          this.$message({
            type:'warning',
            message:'当前没有正在进行的会议'
          })
        }
      });
    }
    
    // this.getlist();
  },
};
</script>
<style>
.itemgroup {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 20px 0 0;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}
.itemgroup * {
  box-sizing: border-box;
}
.item-title {
  line-height: 28px;
}
</style>