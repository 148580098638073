<template>
  <div>
    <div class="maintitle">菜单列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-button type="success" size="mini" @click="addDiv = true">添加新菜单</el-button>
    </el-row>
    <el-table :data="list" row-key="menuid" border :tree-props="{ children: 'aa' }">
      <el-table-column prop="title" label="菜单名称"></el-table-column>
      <el-table-column prop="model" width="100" label="模块名称"></el-table-column>
      <el-table-column prop="controller" width="100" label="控制器名称"></el-table-column>
      <el-table-column prop="action" width="100" label="方法名称"></el-table-column>
      <el-table-column label="状态" width="100" :formatter="formatstatus"></el-table-column>
      <el-table-column label="显示" width="100" :formatter="formathidden"></el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item margin-right" effect="dark" content="修改" placement="top-start">
            <i class="el-icon-edit cursor-pointer" @click="mdfy(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="添加子菜单" placement="top-start">
            <i class="el-icon-plus cursor-pointer" @click="addchild(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" :content='scope.row.status == 1 ? "停用" : "启用"' placement="top-start">
            <i class="cursor-pointer" :class="scope.row.status==1?'el-icon-turn-off':'el-icon-open'" @click="setStatus(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="删除菜单" placement="top-start">
            <i class="el-icon-delete cursor-pointer" @click="del(scope)"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="上移" v-if="scope.$index>0" placement="top-start">
            <i class="el-icon-top cursor-pointer" @click="movePre(scope,'list')"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="下移" v-if="scope.$index<list.length-1" placement="top-start">
            <i class="el-icon-bottom cursor-pointer" @click="moveNext(scope,'list')"></i>
          </el-tooltip>
          <el-tooltip class="item margin-right" effect="dark" content="查看子菜单" placement="top-start">
            <i class="el-icon-document cursor-pointer" @click="showchildren(scope)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-button @click="saveSort">保存排序</el-button>
    <el-dialog :visible.sync="addDiv" :show-close="false" :close-on-click-modal="false" @opened="initinfo">
      <el-form :model="menuinfo" :rules="rules" ref="menuinfo">
        <el-form-item prop="menuid" class="hidden">
          <el-input v-model="menuinfo.menuid" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="父菜单" :label-width="formLabelWidth" class="text-left" prop="pid">
          <el-select v-model="menuinfo.pid" placeholder="请选择">
            <el-option key="-1" label="顶级菜单" :value="0"></el-option>
            <el-option v-for="(item, index) in list" :key="index" :label="item.title" :value="item.menuid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="title">
          <el-input v-model="menuinfo.title" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="模块" :label-width="formLabelWidth" prop="model" required>
          <el-input v-model="menuinfo.model" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="控制器" :label-width="formLabelWidth" prop="controller" required>
          <el-input v-model="menuinfo.controller" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="方法" :label-width="formLabelWidth" prop="action" required>
          <el-input v-model="menuinfo.action" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="菜单显示" class="text-left" prop="isshow">
          <el-radio-group v-model="menuinfo.isshow">
            <el-radio-button label="1">显示</el-radio-button>
            <el-radio-button label="0">不显示</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="状态" class="text-left" prop="status">
          <el-radio-group v-model="menuinfo.status">
            <el-radio-button label="1">正常</el-radio-button>
            <el-radio-button label="0">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="用户类别" class="text-left" prop="group">
          <el-select v-model="menuinfo.group" placeholder="请选择">
            <el-option v-for="(item, index) in grouplist" :key="index" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label-width="formLabelWidth" label="需要登录" class="text-left" prop="requestLogin">
          <el-radio-group v-model="menuinfo.requestlogin">
            <el-radio-button label="1">需要</el-radio-button>
            <el-radio-button label="0">不需要</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="
            resetFormInfo;
            addDiv = false;
          ">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showchildrendiv" width="80%">
      <el-table :data="childlist" border>
        <el-table-column prop="title" label="菜单名称"></el-table-column>
        <el-table-column prop="model" width="100" label="模块名称"></el-table-column>
        <el-table-column prop="controller" width="100" label="控制器名称"></el-table-column>
        <el-table-column prop="action" width="100" label="方法名称"></el-table-column>
        <el-table-column label="状态" width="100" :formatter="formatstatus"></el-table-column>
        <el-table-column label="显示" width="100" :formatter="formathidden"></el-table-column>
        <el-table-column label="内容" width="160">
          <template slot-scope="scope">
            <el-tooltip class="item margin-right" effect="dark" content="修改" placement="top-start">
              <i class="el-icon-edit cursor-pointer" @click="mdfy(scope)"></i>
            </el-tooltip>
            <el-tooltip class="item margin-right" effect="dark" :content='scope.row.status == 1 ? "停用" : "启用"' placement="top-start">
              <i class="cursor-pointer" :class="scope.row.status==1?'el-icon-turn-off':'el-icon-open'" @click="setStatus(scope)"></i>
            </el-tooltip>
            <el-tooltip class="item margin-right" effect="dark" content="删除菜单" placement="top-start">
              <i class="el-icon-delete cursor-pointer" @click="del(scope)"></i>
            </el-tooltip>
            <el-tooltip class="item margin-right" effect="dark" content="上移" v-if="scope.$index>0" placement="top-start">
              <i class="el-icon-top cursor-pointer" @click="movePre(scope,'childlist')"></i>
            </el-tooltip>
            <el-tooltip class="item margin-right" effect="dark" content="下移" v-if="scope.$index<list.length-1" placement="top-start">
              <i class="el-icon-bottom cursor-pointer" @click="moveNext(scope,'childlist')"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showchildrendiv = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //菜单列表
      addDiv: false,
      menuinfo: {
        menuid: 0,
        title: "",
        pid: "",
        model: "",
        controller: "",
        action: "",
        status: 1,
        group: "",
        isshow: 0,
        requestlogin: 1,
        sort: 0,
      },
      crntscope: "",
      grouplist: [],
      showchildrendiv: false,
      childlist: [],
      rules: {
        title: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
          { min: 2, message: "长度不能小于两个汉字", trigger: "blur" },
        ],
        model: [{ required: true, message: "请输入模块名称", trigger: "blur" }],
        controller: [
          { required: true, message: "请输入控制器名称", trigger: "blur" },
        ],
        action: [
          { required: true, message: "请输入方法名称", trigger: "blur" },
        ],
      },
      crntpid: "",
    };
  },
  methods: {
    getlist() {
      this.$axios({
        url: this.HOST + "/admin.menu/getAllList",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list;
          this.grouplist = res.data.grouplist;
        }
      });
    },
    showchildren(scope) {
      this.childlist = scope.row.children;
      this.showchildrendiv = true;
    },
    movePre(scope, list) {
      //元素上移
      let index = scope.$index;
      if (index == 0) {
        return;
      }
      let templist = this[list];
      let temp = templist.splice(index, 1);
      templist.splice(index - 1, 0, temp[0]);
    },
    moveNext(scope, list) {
      //元素下移
      let templist = this[list];
      let index = scope.$index;
      if (index == templist.length - 1) {
        return;
      }
      let temp = templist.splice(index, 1);
      templist.splice(index + 1, 0, temp[0]);
    },
    formatstatus(row, column) {
      return row.status == 1 ? "正常" : "停用";
    },
    formathidden(row, column) {
      return row.isshow == 1 ? "正常" : "隐藏";
    },
    setStatus(scope) {
      let status = scope.row.status == 1 ? 0 : 1;
      this.$axios({
        url: this.HOST + "/admin.menu/setStatus",
        method: "post",
        data: {
          info: {
            menuid: scope.row.menuid,
            status: status,
          },
        },
      }).then((res) => {
        if (res.data.status == 1) {
          scope.row.status = status;
        }
      });
    },
    //重置菜单编辑对话框内容
    resetFormInfo() {
      this.$refs["menuinfo"].resetFields();
      this.crntscope = "";
      this.crntpid = "";
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.addDiv = true;
    },
    initinfo() {
      if (this.crntscope != "") {
        for (let item in this.menuinfo) {
          this.menuinfo[item] = this.crntscope.row[item];
        }
      }
      if (this.crntpid != "") {
        this.menuinfo["pid"] = this.crntpid;
      }
    },
    addchild(scope) {
      this.crntpid = scope.row.menuid;
      this.addDiv = true;
    },
    submdfy() {
      this.$axios({
        url: this.HOST + "/admin.menu/savemenu",
        method: "post",
        data: {
          info: this.menuinfo,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.resetFormInfo();
          this.addDiv = false;
          this.getlist();
        }
      });
    },
    del(scope) {
      this.$confirm(
        '确定要修改菜单"' +
          scope.row.title +
          '"吗？本操作会同步操作子菜单并且不可恢复！！！',
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin.menu/delMenu",
          method: "post",
          data: {
            id: scope.row.menuid,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.getlist();
            this.$message({
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
    saveSort() {
      this.$axios({
        url: this.HOST + "/admin.menu/saveSort",
        method: "post",
        data: {
          list: this.list,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
  },
  created: function () {
    this.getlist();
  },
};
</script>