<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-07 17:33:08
-->
<template>
  <div v-loading="loading">
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter='10'>
      <el-col :span='6'>
        <el-input v-model='keyword' placeholder="请输入搜索关键字" size='mini'></el-input>
      </el-col>
      <!-- <el-select size='mini' v-model='status' class='margin-left' @change='list=[]'>
        <el-option :value='0' label='待购买'></el-option>
        <el-option :value='1' label='待支付'></el-option>
      </el-select> -->
      <el-select size='mini' v-model='area' class='margin-left' @change='list=[]'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button @click="srch" size='mini' class="margin-left">搜索</el-button>
      <!-- <el-button @click="getPay" size='mini' class="margin-left">待支付订单</el-button> -->
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column label='操作' width='150'>
        <template slot-scope="scope">
          <el-button type='text' @click="showBuy(scope)" v-if='scope.row.order_status==0 && scope.row.audit_status==2'>购买</el-button>
          <el-button type='text' @click="syncOrder(scope)" v-if='scope.row.audit_status==1'>更新合同审核状态</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="buyDiv" :show-close="false" :close-on-click-modal="false">
      <el-form class="padding-left">
        <el-form-item label='履约速度偏好' class="text-left" label-width="120px" size="mini">
          <el-select v-model="orderinfo.data.speedFactor">
            <el-option v-for='item in speedlist' :key='item.title' :value='item.title' :label='item.label'></el-option>
          </el-select>
          <el-button @click="syncSpeedList" size="mini" class="margin-left">更新履约速度选项</el-button>
        </el-form-item>
        <el-form-item label='节假日投放' class="text-left" label-width="120px" size="mini">
          <el-select v-model="orderinfo.data.holidayPromotion">
            <el-option :value='2' label='是'></el-option>
            <el-option :value='4' label='否'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='SKU' class="text-left" label-width="120px" size="mini">
          <el-checkbox-group v-model="orderinfo.data.sku_list">
            <!-- <el-checkbox v-for="(item,index) in skulist" :key="index" :label="item.url" :value="item.url" class='text-info'>{{item.coreQuery}}({{item.capacity}})<span @click="showProduct(item.url)" class="margin-left" style='color:#00f'>查看商品</span></el-checkbox> -->
            <el-checkbox v-for="(item,index) in skulist" :key="index" :label="item.url" :value="item.url" class='text-info'>{{item.coreQuery}}:{{item.url}}({{item.capacity}})</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="buyDiv = false">取 消</el-button>
        <el-button type="primary" @click="subBuy">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="payDiv" :show-close="false" :close-on-click-modal="false">
      <el-form class="padding-left">
        <el-form-item label='会员id' class="text-left" label-width="120px">
          {{payinfo.member_id}}
        </el-form-item>
        <el-form-item label='公司名' class="text-left" label-width="120px">
          {{payinfo.company}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleOrder">取消订单</el-button>
        <el-button type="primary" @click="pay">去支付</el-button>
        <el-button type="primary" @click="payDiv=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
      skulist: [],
      keyword: "",
      status: 0,
      buyDiv: false,
      orderinfo: {
        goodsId: "",
        data: {
          memberId: "",
          speedFactor: "",
          holidayPromotion: "",
          sku_list: [],
        },
      },
      speedlist: sessionStorage.getItem("speedlist")
        ? JSON.parse(sessionStorage.getItem("speedlist"))
        : [],
      loading: false,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      payDiv: false,
      payinfo: {
        company: "",
        member_id: "",
        url: "",
      },
      max_sku:window.max_sku
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    getlist() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/orderList",
        method: "post",
        data: {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
          area: this.area,
          status: this.status,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    showBuy(scope) {
      this.sku_list = [];
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/check",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.skulist = res.data.list;
          this.orderinfo.goodsId = scope.row.goodsId;
          this.orderinfo.data.memberId = scope.row.member_id;
          this.orderinfo.data.speedFactor = scope.row.speedFactor;
          this.orderinfo.data.holidayPromotion = scope.row.holidayPromotion;
          this.buyDiv = true;
        }
      });
    },
    pay() {
      window.open(this.payinfo.url, "_bland");
      this.payinfo = {
        company: "",
        member_id: "",
        url: "",
      };
      this.payDiv = false;
    },
    cancleOrder() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/cancleOrder",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type:'success',
            message:res.data.info
          })
          this.payinfo = {
            company: "",
            member_id: "",
            url: "",
          };
          this.payDiv = false;
        }
      });
    },
    syncSpeedList() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getBuySetting",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.speedlist = res.data.speedlist;
          sessionStorage.setItem(
            "speedlist",
            JSON.stringify(res.data.speedlist)
          );
        }
      });
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    showProduct(url){
      window.open(url,"_blank");
    },
    subBuy() {
      if(this.orderinfo.data.sku_list.length>this.max_sku){
        this.$message({
          type:'error',
          message:'一个套餐最多选择'+this.max_sku+'个商品'
        })
        return;
      }
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/buy",
        method: "post",
        data: {
          info: this.orderinfo,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
          this.buyDiv = false;
          this.getlist();
          window.open(res.data.url,'_blank');
        }
      });
    },
    getPay() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getPayList",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.payinfo.company = res.data.company;
          this.payinfo.member_id = res.data.member_id;
          this.payinfo.url = res.data.url;
        }
      });
    },
    syncOrder(scope){
      this.$axios({
        url:this.HOST+'/admin.jiayoubao/syncOrder',
        method:'post',
        data:{
          member_id:scope.row.member_id
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.getlist();
        }
      })
    }
  },
  created: function () {
    this.area = this.arealist[0].id;
    // this.getlist();
  },
};
</script>
