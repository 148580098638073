<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-06 15:44:21
-->
<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter='10'>
      <el-col :span='6'>
        <el-input v-model='keyword' :placeholder="type=='member_id'?'请输入会员id':'请输入公司准确全名'" size='mini'>
          <template slot="prepend">
            <el-select v-model="type" style="width:100px;" @change="keyword=''">
              <el-option value='member_id' label="会员id"></el-option>
              <el-option value='company_name' label="公司名称"></el-option>
            </el-select>
          </template>
        </el-input>
      </el-col>
      <el-select size='mini' v-model='area' class='margin-left' placeholder="请选择所属地区" style='width:80px'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button size='mini' class="margin-left" @click="getMemberInfo">获取会员信息</el-button>
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column prop="unified_code" label="统一信用码"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type='text' @click="checkStatus(scope)">查询认证状态</el-button>
          <el-button type='text' class="margin-left" @click="mdfyQualification(scope)">资质修改</el-button>
          <el-button type='text' class="margin-left" @click="checkQualification(scope)">查询资质修改结果</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="qualificationDiv" :show-close="false" :close-on-click-modal="false" @closed='resetInfo("qualifcation")'>
      <el-form :model="qualificationInfo" v-loading="loading">
        <el-form-item label="会员id" :label-width="formLabelWidth" class="text-left">
          <el-input v-model="qualificationInfo.member_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="公司名" :label-width="formLabelWidth" class="text-left">
          <el-input v-model="qualificationInfo.company"></el-input>
        </el-form-item>
        <el-form-item label="信用码" :label-width="formLabelWidth" class="text-left">
          <el-input v-model="qualificationInfo.unicode" disabled></el-input>
        </el-form-item>
        <el-form-item label="营业执照" :label-width="formLabelWidth" class="text-left">
          <el-upload class="upload-demo" drag :action="HOST + '/admin.member/upattachemnt'" :multiple="false" :data="uploadLicense" name='attachment' :show-file-list="false" :on-success="showRes" v-if="qualificationInfo.license_img == ''">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              上传公司最新营业执照，只能上传jpg/png文件
            </div>
          </el-upload>
          <div class="avatorDiv" style='width:100%' v-if="qualificationInfo.license_img != ''">
            <span>{{qualificationInfo.license_img}}</span>
            <div class="closebtn" @click="delpic('license_img')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="合同" :label-width="formLabelWidth" class="text-left">
          <el-upload class="upload-demo" drag :action="HOST + '/admin.member/upattachemnt'" :multiple="false" :data="uploadContract" name='attachment' :show-file-list="false" :on-success="showRes" v-if="qualificationInfo.contract_img == ''">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              上传合同，如果公司更名，需上传以新公司名签订的合同，只能上传jpg/png/pdf文件
            </div>
          </el-upload>
          <div class="avatorDiv" style='width:100%' v-if="qualificationInfo.contract_img != ''">
            <span>{{qualificationInfo.contract_img}}</span>
            <div class="closebtn" @click="delpic('contract_img')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="变更证明" :label-width="formLabelWidth" class="text-left">
          <el-upload class="upload-demo" drag :action="HOST + '/admin.member/upattachemnt'" :multiple="false" :data="uploadReason" name='attachment' :show-file-list="false" :on-success="showRes" v-if="qualificationInfo.reason_img == ''">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              上传变更证明，只能上传jpg/png/pdf文件
            </div>
          </el-upload>
          <div class="avatorDiv" style='width:100%' v-if="qualificationInfo.reason_img != ''">
            <span>{{qualificationInfo.reason_img}}</span>
            <div class="closebtn" @click="delpic('reason_img')">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="exampleDiv" :show-close="false" :close-on-click-modal="false" append-to-body>
        <div v-html='exampleDetail'></div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="exampleDiv = false">关闭</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showExample">变更证明示例</el-button>
        <el-button @click="qualificationDiv = false">取 消</el-button>
        <el-button type="primary" @click="subInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      list: [],
      keyword: '',
      status: 0,
      type: 'member_id',
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      qualificationDiv: false,
      qualificationInfo: {
        member_id: '',
        company: '',
        unicode: '',
        license_img: '',
        contract_img: '',
        reason_img: '',
        city: '',
        area: ''
      },
      company: '',
      memberIdList: [],
      member_id: '',
      uploadLicense: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
        type: 'license_img'
      },
      uploadContract: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
        type: 'contract_img'
      },
      uploadReason: {
        adminuuid: sessionStorage.getItem("adminuuid"),
        admintoken: sessionStorage.getItem("admintoken"),
        type: 'reason_img'
      },
      tempinfo: {
        qualification: {}
      },
      exampleDiv: false,
      exampleDetail: ''
    };
  },
  methods: {
    getMemberInfo() {
      this.$axios({
        url: this.HOST + '/admin.member/getInfo',
        method: 'post',
        data: {
          keyword: this.keyword,
          type: this.type,
          area: this.area
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list
        }
      })
    },
    checkStatus(scope) {
      this.$axios({
        url: this.HOST + "/admin.member/getAuthEndTime",
        method: "post",
        data: {
          id: scope.row.member_id,
          area: scope.row.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          if (res.data.url) {
            this.$confirm(scope.row.company_name + res.data.msg, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              window.open(res.data.url, "_blank");
            });
          } else if (res.data.authstatus == 0) {
            this.$confirm(scope.row.company_name + res.data.msg, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.authentication(scope);
            });
          } else {
            this.$confirm(scope.row.company_name + res.data.msg, {
              confirmButtonText: "重新认证",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.authentication(scope);
            });
          }
        }
      });
    },
    authentication(scope) {
      this.loading = true;
      let url = this.HOST + "/admin.member/getAuthenticationUrl";
      this.$axios({
        url: url,
        method: "post",
        data: {
          member_id: scope.row.member_id,
          type:'member_id',
          area:scope.row.area
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          window.open(res.data.url, "_blank");
        }
      });
    },
    showRes(res) {
      this.loading = false;
      if (res.status == 1) {
        this.qualificationInfo[res.type] = res.file
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
      console.log(this.spinfo);
    },
    mdfyQualification(scope) {
      this.qualificationInfo.member_id = scope.row.member_id;
      this.qualificationInfo.company = scope.row.company_name;
      this.qualificationInfo.unicode = scope.row.unified_code;
      this.qualificationInfo.city = scope.row.city;
      this.qualificationInfo.area = scope.row.area;
      this.qualificationDiv = true;
    },
    checkQualification(scope) {
      this.$axios({
        url: this.HOST + '/admin.member/checkQualificationMdfy',
        method: 'post',
        data: {
          member_id: scope.row.member_id,
          area: scope.row.area,
        }
      }).then(res => {
        if (res.data.status == 1) {
          let str = '当前状态：' + res.data.mdfyStatus;
          if (res.data.failreason != '') {
            str += '<br />失败原因：' + res.data.failreason
          }
          this.$alert(str, '查询成功', {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true
          });
        }
      })
    },
    delpic(str) {
      this.$axios({
        url: this.HOST + '/admin.sp/delpic',
        method: 'post',
        data: {
          pic: this.qualificationInfo[str]
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.qualificationInfo[str] = "";
        }
      })
    },
    subInfo() {
      this.loading = true;
      this.$axios({
        url: this.HOST + '/admin.member/qualificationMdfy',
        method: 'post',
        data: {
          info: this.qualificationInfo
        }
      }).then(res => {
        this.loading = false;
        if (res.data.status = 1) {
          this.$message({
            type: 'success',
            message: res.data.info
          })
        }
      })
    },
    resetInfo(str) {
      for (let item in this[str + 'Info']) {
        this[info[i] + 'Info'][item] = this.tempinfo[info[i]][item]
      }
    },
    showExample() {
      if (this.exampleDetail == '') {
        this.$axios({
          url: this.HOST + '/admin.index/getArticle',
          method: 'post',
          data: {
            type: 'qualification_reason'
          }
        }).then(res => {
          if (res.data.status == 1) {
            this.exampleDetail = res.data.detail;
            this.exampleDiv = true;
          }
        })
      } else {
        this.exampleDiv = true;
      }
    }
  },
  created: function () {
    this.area = this.arealist[0].id;
    let info = ['qualification'];
    for (let i in info) {
      for (let item in this[info[i] + 'Info']) {
        this.tempinfo[info[i]][item] = this[info[i] + 'Info'][item]
      }
    }
  },
};
</script>