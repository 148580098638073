<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-10-21 15:35:00
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-04-07 17:33:08
-->
<template>
  <div v-loading="loading">
    <div class="maintitle">{{title}}</div>
    <el-divider></el-divider>
    <el-row class="text-left" :gutter='10'>
      <el-col :span='6'>
        <el-input v-model='keyword' placeholder="请输入搜索关键字" size='mini'></el-input>
      </el-col>
      <!-- <el-select size='mini' v-model='status' class='margin-left' @change='list=[]'>
        <el-option :value='1' label='待支付'></el-option>
      </el-select> -->
      <el-select size='mini' v-model='area' class='margin-left' @change='list=[]'>
        <el-option v-for='item in arealist' :key='item.id' :value="item.id" :label="item.value"></el-option>
      </el-select>
      <el-button @click="srch" size='mini' class="margin-left">搜索</el-button>
      <!-- <el-button @click="getPay" size='mini' class="margin-left">待支付订单</el-button> -->
    </el-row>
    <el-table :data="list" row-key="mid" border>
      <el-table-column prop="member_id" label="会员id" width="100"></el-table-column>
      <el-table-column prop="company_name" label="公司名"></el-table-column>
      <el-table-column label='操作' width='150'>
        <template slot-scope="scope">
          <el-button type='text' @click="syncOrder(scope)" v-if='scope.row.order_status!=0'>查看订单信息</el-button>
          <!-- <el-button type='text' @click="pay(scope)" v-if='scope.row.realstatus!=1'>支付</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="buyDiv" :show-close="false" :close-on-click-modal="false">
      <el-form class="padding-left">
        <el-form-item label='履约速度偏好' class="text-left" label-width="120px" size="mini">
          <el-select v-model="orderinfo.speedFactor">
            <el-option v-for='item in speedlist' :key='item.title' :value='item.title' :label='item.label'></el-option>
          </el-select>
          <el-button @click="syncSpeedList" size="mini" class="margin-left">更新履约速度选项</el-button>
        </el-form-item>
        <el-form-item label='节假日投放' class="text-left" label-width="120px" size="mini">
          <el-select v-model="orderinfo.holidayPromotion">
            <el-option :value='2' label='是'></el-option>
            <el-option :value='4' label='否'></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="buyDiv = false">取 消</el-button>
        <el-button type="primary" @click="subMdfy">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="orderlistDiv" :show-close="false" :close-on-click-modal="false" width='80%'>
      <el-table :data="orderlist" border>
        <el-table-column prop="order_id" label="订单id"></el-table-column>
        <el-table-column label="商品">
          <template slot-scope="scope">
            {{formatGoods(scope.row.goods_id)}}
          </template>
        </el-table-column>
        <el-table-column prop="speed_factor_msg" label="履约速度"></el-table-column>
        <el-table-column prop="holiday_promotion_msg" label="节假日投放"></el-table-column>
        <el-table-column prop="order_status_step" label="当前状态"></el-table-column>
        <el-table-column prop="begin_time" label="开始时间"></el-table-column>
        <el-table-column label='操作' width='200'>
          <template slot-scope="scope">
            <el-button type='text' @click="showItem(scope)" v-if='scope.row.order_status!=0'>查看订单信息</el-button>
            <el-button type='text' @click="mdfy(scope)" v-if='scope.row.realstatus!=0'>修改</el-button>
            <el-button type='text' @click="stop(scope)" v-if='scope.row.realstatus!=0'>{{scope.row.jyb_contract_status==2?'暂停':'恢复'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="orderitemDiv" :show-close="false" :close-on-click-modal="false" append-to-body>
        <el-row :gutter="10" v-for='item in orderItemList' :key='item'>
          <el-col :span='18'>{{item}}</el-col>
          <el-col :span='6'>
            <el-button size="mini" @click="showProduct(item)">查看商品</el-button>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="orderitemDiv=false">关闭</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="orderlistDiv=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //会议列表
      page: 1,
      total: 0,
      skulist: [],
      keyword: "",
      status: 1,
      buyDiv: false,
      orderinfo: {
        member_id: "",
        speedFactor: "",
        holidayPromotion: "",
        order_id: "",
      },
      speedlist: sessionStorage.getItem("speedlist")
        ? JSON.parse(sessionStorage.getItem("speedlist"))
        : [],
      loading: false,
      area: "",
      arealist: JSON.parse(sessionStorage.getItem("arealist")),
      orderlist: [],
      orderlistDiv: false,
      orderitemDiv: false,
      orderItemList: [],
      contractlist: [],
      crntscope:''
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    getlist() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/orderList",
        method: "post",
        data: {
          keyword: this.keyword,
          page: this.page,
          pagesize: this.pagesize,
          area: this.area,
          status: this.status,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    formatGoods(goods_id) {
      for (let item in this.contractlist) {
        if (this.contractlist[item].buytype == goods_id)
          return this.contractlist[item].label;
      }
      return "未知";
    },
    showItem(scope) {
      this.orderItemList = scope.row.loc_list.split(",");
      this.orderitemDiv = true;
    },
    mdfy(scope) {
      this.orderinfo.member_id = this.crntscope.row.member_id;
      this.orderinfo.speedFactor = scope.row.speed_factor;
      this.orderinfo.holidayPromotion = scope.row.holiday_promotion;
      this.orderinfo.order_id = scope.row.order_id;
      this.buyDiv = true;
    },
    cancleOrder() {
      this.$confirm("确定要取消订单吗？本操作不可恢复！！！", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/admin.jiayoubao/cancleOrder",
          method: "post",
          data: {
            area: this.area,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              type: "success",
              message: res.data.info,
            });
            this.payinfo = {
              company: "",
              member_id: "",
              url: "",
            };
            this.payDiv = false;
          }
        });
      });
    },
    syncSpeedList() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getBuySetting",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.speedlist = res.data.speedlist;
          sessionStorage.setItem(
            "speedlist",
            JSON.stringify(res.data.speedlist)
          );
        }
      });
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    subMdfy() {
      this.loading = true;
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/mdfyOrder",
        method: "post",
        data: {
          info: this.orderinfo,
        },
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
          this.buyDiv = false;
        }
      });
    },
    //修改订单履约状态
    stop(scope){
      let action = scope.row.jyb_contract_status==2?'pause':'recover';
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/stopOrder",
        method: "post",
        data: {
          member_id:this.crntscope.row.member_id,
          order_id:scope.row.order_id,
          action:action
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: res.data.info,
          });
        }
      });
    },
    getPay() {
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getPayList",
        method: "post",
        data: {
          area: this.area,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.payinfo.company = res.data.company;
          this.payinfo.member_id = res.data.member_id;
          this.payinfo.url = res.data.url;
          this.payDiv = true;
        }
      });
    },
    syncOrder(scope) {
      this.crntscope = scope;
      this.$axios({
        url: this.HOST + "/admin.jiayoubao/getOrderInfo",
        method: "post",
        data: {
          member_id: scope.row.member_id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          if (res.data.list.length == 0) {
            this.$message({
              type: "success",
              message: "查询成功，暂无订单",
            });
          } else {
            this.orderlist = res.data.list;
            this.orderlistDiv = true;
          }
        }
      });
    },
    showProduct(url) {
      window.open(url, "_blank");
    },
    getGoodsTypeList() {
      if (!sessionStorage.getItem("contractlist")) {
        this.$axios({
          url: this.HOST + "/admin.jiayoubao/getContractList",
          method: "post",
          data: {},
        }).then((res) => {
          if (res.data.status == 1) {
            this.contractlist = res.data.list;
            sessionStorage.setItem(
              "contractlist",
              JSON.stringify(res.data.list)
            );
          }
        });
      } else {
        this.contractlist = JSON.parse(sessionStorage.getItem("contractlist"));
      }
    },
  },
  created: function () {
    this.area = this.arealist[0].id;
    this.getGoodsTypeList();
    // this.getlist();
  },
};
</script>
