<template>
  <div>
    <div class="maintitle">参数设置</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">网站名称</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.webname"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isadmin==1">
      <el-col :span="6" class="text-right">用户登录方式</el-col>
      <el-col :span="18" class="text-left">
        <el-radio-group v-model="info.account_type" size="mini">
          <el-radio label="1" border>账号登录</el-radio>
          <el-radio label="2" border>手机号登录</el-radio>
          <el-radio label="3" border>账号/手机号登录</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">备案号</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.beian"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">备案号跳转地址</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.beian_url"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">公安备案号</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.gonganbeian"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">公安备案号跳转地址</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.gonganbeian_url"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">每天最多错误次数</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.max_error_num">
          <template slot="append">次</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isadmin==1">
      <el-col :span="6" class="text-right">token刷新方式</el-col>
      <el-col :span="18" class="text-left">
        <el-radio-group v-model="info.refresh_token" size="mini">
          <el-radio label="0" border>登录时刷新</el-radio>
          <el-radio label="1" border>每次请求都刷新</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="info.refresh_token==1 && isadmin==1">
      <el-col :span="6" class="text-right">token有效期</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.outtime">
          <template slot="append">分</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isadmin==1">
      <el-col :span="6" class="text-right">token存储方式</el-col>
      <el-col :span="18" class="text-left">
        <el-radio-group v-model="info.token_type" size="mini">
          <el-radio label="mysql" border>mysql</el-radio>
          <el-radio label="redis" border>redis</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isadmin==1">
      <el-col :span="6" class="text-right">操作员组</el-col>
      <el-col :span="18" class="text-left">
        <el-radio-group v-model="info.use_group" size="mini">
          <el-radio label="0" border>不使用操作员组</el-radio>
          <el-radio label="1" border>使用操作员组</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" class="text-right">新操作员默认密码</el-col>
      <el-col :span="18" class="text-left">
        <el-input size="mini" style="width:200px" v-model="info.default_password"></el-input>
      </el-col>
    </el-row>
    <el-button type="primary" @click="mdfysetting">修改</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {}, //参数信息
      isadmin:0, // 是否总管理员
    };
  },
  methods: {
    getsetting() {
      this.$axios({
        url: this.HOST + "/admin.setting/getSetting",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.info = res.data.info;
          this.isadmin = res.data.isadmin;
        }
      });
    },
    mdfysetting() {
      this.$confirm(
        "确定要修改配置吗？部分配置会影响到在线用户，建议在用户访问少的时候进行修改",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          url: this.HOST + "/admin.setting/mdfySetting",
          method: "post",
          data: {
            info: this.info,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.getsetting();
            this.$message({
              type: "info",
              message: "操作成功",
            });
          }
        });
      });
    },
  },
  created: function () {
    this.getsetting();
  },
};
</script>
<style>
.toplevel {
  width: 100%;
  font-weight: bold;
}
</style>