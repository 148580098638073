/*
 * @description: 
 * @Author: HaoJG
 * @Date: 2021-08-09 10:48:34
 * @Lasteditors: HaoJG
 * @LastEditTime: 2021-10-11 11:27:47
 */
const whiteList = [
    '/login',
    '/baoming'
]

export default whiteList