<!--
 * @Author: HaoJG
 * @Date: 2020-11-27 14:00:34
 * @Lasteditors: HaoJG
 * @LastEditTime: 2021-08-09 10:47:58
-->
<template>
  <div id="app">
    <router-view style="width: 100%;height: 100%;" :key="$route.path"></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "app",
  methods: {
    ...mapActions({}),
  },
  created() {},
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.el-container:first-child {
  height: 100%;
}
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-header,
.el-footer,
.el-aside {
  background-color: #141c23;
  color: #ffffff;
  line-height: 60px;
}
.el-menu-item.is-active {
  border-right: 3px solid #ffd04b;
}

.el-table {
  width: 100%;
  margin-bottom: 10px;
}

aside {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  .el-message-box {
    width: 80% !important;
  }
}
</style>
